export default {
    'app-title': 'FBS',
    'app-title-2': 'မရောင်းနိုင်',
    'app-title-language': 'ဘာသာစကား',
    'title-detail': 'အသေးစိတ်',
    'title-welcome_to_use': 'သုံးရန် ကြိုဆိုပါသည်',

    //tabBar
    'tabbar-market': 'စျေးကွက်',
    'tabbar-order': 'အော်ဒါများ',
    'tabbar-news': 'သတင်းများ',
    'tabbar-mine': 'ကျွန်ုပ်၏',
    'public-night_mode': 'ညပိုင်းပုံစံ',
    'public-noRecord': 'ဒေတာမရှိသေး',
    'public-text-cancel': 'ပယ်ဖျက်ပါ',
    'public-text-confirm': 'အတည်ပြုပါ',
    'public-text-balance': 'အပိုင်းလိုက်',
    'public-text-date': 'ရက်စွဲ',
    'public-text-completed': 'ပြီးဆုံးသည်',
    'public-text-invalid': 'မတရား',
    'public-text-unchecked': 'စစ်ဆေးခြင်းမရှိ',
    'public-text-remarks': 'မှတ်ချက်များ',
    'public-text-loading': 'ဖြည့်စွက်နေသည်',
    'public-text-loose-to-refresh': 'ပြန်လည်အသစ်စဉ်ဖျက်ပါ...',
    'public-text-buy': 'ဝယ်ယူပါ',
    'public-text-sell': 'ရောင်းပါ',
    'public-text-close': 'ပိတ်သည်',
    'public-text-lots_1': 'လော့များ',
    'public-text-lots': 'လော့များ',
    'public-text-available': 'ရရှိနိုင်သော',
    'public-text-freeze': 'အခေတ္တအားနည်း',
    'public-text-scroll_down_refresh': 'အောက်သို့ပြောင်းပြီး ပြန်လည်အသစ်စဉ်ဖျက်ပါ...',
    'public-btn-submit': 'အပြန်ပို့ပါ',
    'public-btn-add': 'ထည့်ပါ',
    'public-btn-copy': 'ကူးယူပါ',
    'public-btn-delete': 'ဖျက်ပါ',
    'public-btn-copyLink': 'ဖိတ်ခေါ်လင့်ခ် ကူးယူပါ',
    'public-btn-send': 'ပို့ပါ',
    'public-btn-download': 'ဒေါင်းလုပ်လုပ်ပါ',
    'public-btn-resetPassword': 'စကားဝှက်ပြန်လည်သတ်မှတ်ပါ',
    'public-btn-confirmation': 'အတည်ပြုပါ',
    'public-btn-accept': 'လက်ခံပါ',
    'public-enterEmail': 'သင့်အီးမေးလ်ထည့်ပါ',
    'public-password': 'စကားဝှက်',
    'public-retypePassword': 'စကားဝှက်ကို ပြန်ထည့်ပါ',
    'public-inviteCode': 'ဖိတ်ခေါ်ကုဒ်',
    'public-verifyCode': 'အတည်ပြုမှုကုဒ်',
    'login-newUser': 'အသစ်သုံးသူလား?',
    'login-joinNow': 'ယခု ကြိုးစားပါ',
    'login-forgotPassword': 'စကားဝှက်မေ့နေပါသလား',
    'login-btn-login': 'ဝင်ရောက်ပါ',
    'login-btn-demoAcc': 'အကောင့်ဖွင့်ရန်လိုအပ်သောအရာမရှိ၊ ယခုတစ်လျှောက် demo အကောင့်ကိုဖန်တီးပါ!',
    'login-enterEmail': 'ကျေးဇူးပြု၍ သင်၏ အီးမေးလ်လိပ်စာထည့်ပါ',
    'login-enterPassword': 'ကျေးဇူးပြု၍ သင်၏ စကားဝှက်ထည့်ပါ',
    'regsiter-btn-register': 'မှတ်ပုံတင်ပါ',
    'regsiter-enterInviteCode': 'ကျေးဇူးပြု၍ ဖိတ်ခေါ်ကုဒ်ထည့်ပါ',
    'regsiter-enterVerityCode': 'ကျေးဇူးပြု၍ အတည်ပြုမှုကုဒ်ထည့်ပါ',
    'logout-title': 'ထွက်မည်',
    'systemHint-reset_successful': 'ပြန်လည်သတ်မှတ်ခြင်းအောင်မြင်သည်',
    'systemHint-incorrectVerityCode': 'မမှန်ကန်သော အတည်ပြုမှုကုဒ်',
    'systemHint-passwordsDoNotMatch': 'စကားဝှက်များမကိုက်ညီ',
    'systemHint-password_error': 'စကားဝှက်မှားနေသည်',
    'systemHint-login_connot_find_user': 'ဤအသုံးပြုသူကို မတွေ့ပါ',
    'systemHint-copySuccess': 'ကူးယူမှုအောင်မြင်ပါသည်',
    'systemHint-plsCompleted': 'ကျေးဇူးပြု၍ ပြည့်စုံစွာဖြည့်ပါ',
    'systemHint-pwdInconsistent': 'စကားဝှက်များမကိုက်ညီ',
    'systemHint-incorrectVerifyCode': 'မမှန်ကန်သော အတည်ပြုမှုကုဒ်',
    'systemHint-changeSuccess': 'ပြောင်းလဲမှုအောင်မြင်သည်',
    'systemHint-plsSubmit': 'ကျေးဇူးပြု၍ အချက်အလက်အပြည့်အစုံကို တင်ပြပါ',
    'systemHint-binb-bank-not-open-yet': 'ဤငွေလဲလှယ်မှုကို အပိတ်မထားသေးပါ',
    'systemHint-plsFillIn': 'ကျေးဇူးပြု၍ ပြည့်စုံစွာဖြည့်ပါ',
    'systemHint-requestSuccessful': 'တောင်းဆိုမှုအောင်မြင်ပါသည်',
    'systemHint-operation_successful': 'အလုပ်လုပ်မှုအောင်မြင်သည်',
    'systemHint-set_successful': 'သတ်မှတ်မှုအောင်မြင်သည်',
    'systemHint-missing_params-or-val-error': 'လိုအပ်သောပါရာမီတာများလွတ်ကင်းသို့မဟုတ် တန်ဖိုးမှားယွင်းခြင်း',
    'systemHint-limit_trade_price_must_greater': 'နိမိတ်ကုန်သွယ်ဈေးနှုန်းသည် 0 ထက်ပိုမရှိရပါ',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'ဝယ်ယူမှု (ရှည်) သတ်မှတ်ချိန်ဈေးနှုန်းသည် ဖွင့်ခြင်းဈေးနှုန်းထက် အမြင့်မဖြစ်ရ',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'ဝယ်ယူမှု (ရှည်) ရောင်းအားရရှိဖို့ဈေးနှုန်းသည် ဖွင့်ခြင်းဈေးနှုန်းထက် အမြင့်မဖြစ်ရ',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'ရောင်းချမှု (တို) သတ်မှတ်ချိန်ဈေးနှုန်းသည် ဖွင့်ခြင်းဈေးနှုန်းထက် နိမ့်မဖြစ်ရ',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'ရောင်းချမှု (တို) ရောင်းအားရရှိဖို့ဈေးနှုန်းသည် ဖွင့်ခြင်းဈေးနှုန်းထက် နိမ့်မဖြစ်ရ',
    'systemHint-code_send_succesful': 'အတည်ပြုမှုကုဒ် ပို့ခြင်းအောင်မြင်သည်',
    'systemHint-pls_register_login_real_account': 'ကျေးဇူးပြု၍ အပြန်သို့မှတ်ပုံတင်ပါ သို့မဟုတ် အမှန်တကယ်အကောင့်သို့ ဝင်ပါ',
    'systemHint-successful_exit': 'ထွက်မှုအောင်မြင်သည်',
    'popupHint-set_up_collection_account': 'ရယူခြင်းအကောင့်ကို ပထမဦးဆုံး သတ်မှတ်ပါ',
    'popupHint-confirm_to_delete': 'ဖျက်ရန် အတည်ပြုမည်နည်း',
    'popupHint-whether_return_it': 'ပြန်သွားရန်လား',
    'mine-availableAssets': 'ရရှိနိုင်သောပစ္စည်းများ',
    'mine-account_details': 'အကောင့်အရေအတွက်များ',
    'mine-deposit': 'ငွေစုပါ',
    'mine-withdraw': 'ငွေပေးချေ',
    'mine-billDetails': 'ဘီလ်အသေးစိတ်',
    'mine-demo_return_simulated_account': 'စမ်းသပ်အကောင့်သို့ ပြန်သွားပါ',
    'mine-wallet': 'ပတ်ဖို့',
    'mine-verifyName': 'တကယ့်နာမည်အတည်ပြုပါ',
    'mine-inviteFriend': 'မိတ်ဆွေများကို ဖိတ်ခေါ်ပါ',
    'mine-changePassword': 'စကားဝှက်ပြောင်းပါ',
    'mine-onlineSerive': 'အွန်လိုင်းဝန်ဆောင်မှု',
    'mine-complaintEmail': 'ဖျက်သိမ်းရမည့် အီးမေးလ်',
    'mine-announcement': 'ကြေငြာချက်',
    'mine-establishment': 'တည်ထောင်ခြင်း',
    'mine-other': 'အခြား',
    'mine-change_theme': 'ပုံစံပြောင်းပါ',
    'dialog-title': 'သတိပေးချက်',
    'dialog-logout-conetnt': 'ထွက်မည်ကို သေချာပါသလား?',
    'announce-text': 'မူဝါဒအရ အမေရိကန်ပြည်ထောင်စု၊ အီရှရွေယ်၊ တရုတ်၊ ဗနွေတူ၊ နှင့် ကူးဘားကို ဝန်ဆောင်မှုများမပေးပါ။',
    'announce-text-demo': '(FBS စမ်းသပ်ရန်သာ)',
    'verify-text-upload': 'ကျေးဇူးပြု၍ သင်၏ ပတ်စ်ပို့စ်/အိုင်ဒီ၏ မျက်နှာခုံနှင့် နောက်ခံကို တင်ပါ',
    'verify-front': 'ရှေ့ပိုင်း',
    'verify-front-desc': 'သင်၏ ပတ်စ်ပို့စ်/သက်သာမှတ်ပုံတင်အတည်ပြုမှုရှေ့ပိုင်းတင်ပါ',
    'verify-back': 'နောက်ပိုင်း',
    'verify-back-desc': 'သင်၏ ပတ်စ်ပို့စ်/သက်သာမှတ်ပုံတင်အတည်ပြုမှုနောက်ပိုင်းတင်ပါ',
    'verify-text-info': 'သင့်ပုဂ္ဂိုလ်ရေး အချက်အလက်များကို ပြည့်စုံစွာဖြည့်ပါ',
    'verify-name': 'နာမည်',
    'verify-enterName': 'သင်၏နာမည်ထည့်ပါ',
    'verify-passportId': 'ပတ်စ်ပို့စ်/အိုင်ဒီနံပါတ်',
    'verify-enterPassportId': 'သင်၏ ပတ်စ်ပို့စ်/အိုင်ဒီနံပါတ်ထည့်ပါ',
    'verify-infoAuth': 'စစ်ဆေးမှု',
    'wallet-bind-bank': 'ဘဏ်ကတ်ဆက်သွယ်ပါ',
    'wallet-bind-currency-address': 'ဒစ်ဂျစ်တယ်ငွေလိပ်စာဆက်သွယ်ပါ',
    'bind-bank-name': 'ဘဏ်အမည်',
    'bind-bank-address': 'ဘဏ်လိပ်စာ',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'ငွေပေးချေသူ',
    'bind-bank-payee-account': 'ငွေပေးချေသူအကောင့်',
    'bind-currency': 'ငွေ',
    'bind-wallet-address': 'ပတ်ဖို့လိပ်စာ',
    'deposit-digitai-currency': 'ဒစ်ဂျစ်တယ်ငွေ',
    'deposit-bank-card': 'ဘဏ်ကတ်',
    'deposit-select_network': 'နယ်ပယ်ရွေးပါ',
    'deposit-coin-number': 'ကတ်နံပါတ်',
    'deposit-coin-placeholder': 'ခေါ်ယူရန် ကတ်နံပါတ်ထည့်ပါ',
    'doposit-upload-cert': 'အသိအမှတ်ပြုတင်ပြရန်',
    'deposit-record': 'ငွေလွှဲမှတ်တမ်း',
    'deposit-recharge_amount': 'ငွေထည့်မည့်အမျိုးအစား',
    'deposit-recharge-placeholder': 'ငွေထည့်မည့်အမျိုးအစားထည့်ပါ',
    'deposit-recharge_type': 'အမျိုးအစား',
    'deposit-recharge_unit': 'ယူနစ်',
    'deposit-recharge_status': 'အခြေအနေ',
    'deposit-receipt_currency': 'ရ receipt ငွေ',
    'withdraw-coin_address': 'ငွေပေးချေမည့်လိပ်စာ',
    'withdraw-coin_amount': 'ငွေပမာဏ',
    'withdraw-handling_fee': 'စီမံခန့်ခွဲမှုအခွန်',
    'withdraw-expected': 'မျှော်လင့်ထားသည်',
    'withdraw-btn-submit': 'ပေးချေနိုင်သော ပေးပို့ပါ',
    'withdraw-coin_withdrawal_record': 'ငွေထုတ်မှတ်တမ်း',
    'withdraw-num_of_receipt': 'ရ Receipt အရေအတွက်',
    'withdraw-select_addess': 'အခွင့်အရေးအသုံးပြုရန် လိပ်စာရွေးပါ',
    'order-position_holding': 'အရပ်စမ်းနေခြင်း',
    'order-pending_orders': 'စောင့်ဆိုင်းရသော အော်ဒါများ',
    'order-history': 'သမိုင်း',
    'order-profit_loss': 'အမြတ်နှင့်ဆုံးရှုံးမှု',
    'order-current_margin': 'လက်ရှိ Margin',
    'order-risk_rate': 'စိန့်လားမှုရနံပါတ်',
    'order-order_id': 'အော်ဒါ ID',
    'order-total_profit_loss': 'စုစုပေါင်း အမြတ်နှင့်ဆုံးရှုံးမှု',
    'order-order_details': 'အော်ဒါအသေးစိတ်',
    'order-margin': 'Margin',
    'order-take_profit': 'အမြတ်ယူပါ',
    'order-set_loss': 'ဆုံးရှုံးမှု သတ်မှတ်ပါ',
    'order-set_TPSL': 'TP/SL သတ်မှတ်ပါ',
    'order-close_position': 'အရပ်ပိတ်ပါ',
    'order-cancel_order': 'အော်ဒါကို ဖျက်ပါ',
    'order-select_period': 'ကာလရွေးပါ',
    'order-btn_rise': 'တက်စီး',
    'order-btn_fall': 'ကျ',
    'order-estimated_return': 'မျှော်လင့်ရလဒ်',
    'order-purchase_amount': 'ဝယ်ယူပမာဏ',
    'search-search_product': 'ကုန်ပစ္စည်းရှာပါ',
    'search-search_result': 'ရှာဖွေရလဒ်များ',
    'trade-market_price': 'စျေးကွက်ဈေးနှုန်း',
    'trade-price': 'ဈေးနှုန်း',
    'trade-quick_trade': 'မြန်ဆန်သော အတူတကွ ကုန်သွယ်ခြင်း',
    'trade-please_enter_price': 'ဈေးနှုန်းကို ထည့်ပါ',
    'trade-each_lots': 'ချိတ်ဆက်တစ်ခုစီ',
    'trade-estimated_handling_fee': 'မျှော်လင့်ရသုံးစွဲမှုအခွန်',
    'trade-estimated_margin': 'မျှော်လင့်ရ Margin',
    'trade-lots_text': 'Lotti (အဆင့်: 0.01)',
    'trade-order_page': 'အော်ဒါစာမျက်နှာ',
    'trade-your_order_has_been_confirmed': 'သင့်အော်ဒါကို အတည်ပြုပါပြီ',
    'trade-multiplier': 'မြှင့်တင်မှု',
    'accHistory-financial_records': 'ဘဏ္ဍာရေးမှတ်တမ်းများ',
    'accHistory-records': 'မှတ်တမ်းများ',

    'Res_Msg1': 'အီးမေးလ်ကို ရိုက်ထည့်ပါ',
    'Res_Msg2': 'အီးမေးလ် ပုံစံမှားနေပါသည်',
    'Res_Msg3': 'လျို့ဝှက်နံပါတ်ကို ရိုက်ထည့်ပါ',
    'Res_Msg4': 'လျို့ဝှက်နံပါတ်နှင့် အတည်ပြုလျှို့ဝှက်နံပါတ် မကိုက်ညီပါ',
    'Res_Msg5': 'လျို့ဝှက်နံပါတ်သည် ၆ အက္ခရာအထက် ရှိရပါမည်',
    'Res_Msg6': 'အတည်ပြုကုဒ်မှားနေပါသည်',
    'Res_Msg7': 'အတည်ပြုကုဒ်ကို ရိုက်ထည့်ပါ',
    'Res_su': 'မှတ်ပုံတင်ခြင်း အောင်မြင်ပါသည်',
    'Login_su': 'လော့ဂ်အင် အောင်မြင်ပါသည်',
    'Loading': 'ပိုမိုရှိသောဒေတာ မရှိပါ',
    'SDealCont_TopName': 'ပို့ဆောင်မှု',
    'SDealCont_L1': 'ဝယ်ယူရန် အခြေအနေ',
    'SDealCont_L2': 'လက်ရှိစျေးနှုန်း',
    'SDealCont_L3': 'အရေအတွက်',
    'SDealCont_L4': 'ပမာဏ',
    'SDealCont_L5': 'ပို့ဆောင်မှု အချိန်',
    'SDealCont_btn': 'ပိတ်မည်',
    'SDealCont_L6': 'ဝယ်လိုက်',
    'SDealCont_L7': 'ရောင်းလိုက်',
    'SDealCont_L8': 'ဝယ်ယူမည်',
    'SDealCont_L9': 'ရောင်းမည်',
    'verify-verified': 'အတည်ပြုခြင်း အောင်မြင်ပါသည်',
    'verify-notVerified': 'အတည်ပြုခြင်း ဖျက်သိမ်းပါသည်'
}
