export default{
    "app-title": "FBS",
    "app-title-2": "No a la venta",
    "app-title-language": "Idioma",
    "title-detail": "Detalle",
    "title-welcome_to_use": "Bienvenido a usar",

    //tabBar
    "tabbar-market": "Mercado",
    "tabbar-order": "Pedido",
    "tabbar-news": "Noticias",
    "tabbar-mine": "Mi Área",
    "public-night_mode": "Modo Noche",
    "public-noRecord": "No hay datos",
    "public-text-cancel": "Cancelar",
    "public-text-confirm": "Confirmar",
    "public-text-balance": "Saldo",
    "public-text-date": "Fecha",
    "public-text-completed": "Completado",
    "public-text-invalid": "Inválido",
    "public-text-unchecked": "No verificado",
    "public-text-remarks": "Comentarios",
    "public-text-loading": "Cargando",
    "public-text-loose-to-refresh": "Suelte para actualizar...",
    "public-text-buy": "Comprar",
    "public-text-sell": "Vender",
    "public-text-close": "Cerrar",
    "public-text-lots_1": "Piezas",
    "public-text-lots": "Piezas",
    "public-text-available": "Disponible",
    "public-text-freeze": "Congelado",
    "public-text-scroll_down_refresh": "Deslizar hacia abajo para actualizar...",
    "public-btn-submit": "Enviar",
    "public-btn-add": "Agregar",
    "public-btn-copy": "Copiar",
    "public-btn-delete": "Eliminar",
    "public-btn-copyLink": "Copiar enlace de invitación",
    "public-btn-send": "Enviar",
    "public-btn-download": "DESCARGAR",
    "public-btn-resetPassword": "Restablecer contraseña",
    "public-btn-confirmation": "Confirmación",
    "public-btn-accept": "Aceptar",
    "public-enterEmail": "Ingrese su dirección de correo electrónico",
    "public-password": "Contraseña",
    "public-retypePassword": "Reingrese la contraseña",
    "public-inviteCode": "Código de invitación",
    "public-verifyCode": "Código de verificación",
    "login-newUser": "¿Nuevo usuario?",
    "login-joinNow": "Únete ahora",
    "login-forgotPassword": "Olvidé mi contraseña",
    "login-btn-login": "Iniciar sesión",
    "login-btn-demoAcc": "¡No se requiere cuenta, crea una cuenta demo para operar ahora!",
    "login-enterEmail": "Por favor, ingrese su dirección de correo electrónico",
    "login-enterPassword": "Por favor, ingrese su contraseña",
    "regsiter-btn-register": "Registrarse",
    "regsiter-enterInviteCode": "Por favor, ingrese el código de invitación",
    "regsiter-enterVerityCode": "Por favor, ingrese el código de verificación",
    "logout-title": "Cerrar sesión",
    "systemHint-reset_successful": "Restablecimiento exitoso",
    "systemHint-incorrectVerityCode": "Código de verificación incorrecto",
    "systemHint-passwordsDoNotMatch": "Las contraseñas no coinciden",
    "systemHint-password_error": "Error de contraseña",
    "systemHint-login_connot_find_user": "Usuario no encontrado",
    "systemHint-copySuccess": "Copiado con éxito",
    "systemHint-plsCompleted": "Por favor complete",
    "systemHint-pwdInconsistent": "Contraseña inconsistente",
    "systemHint-incorrectVerifyCode": "Código de verificación incorrecto",
    "systemHint-changeSuccess": "Cambio exitoso",
    "systemHint-plsSubmit": "Por favor envíe información completa",
    "systemHint-binb-bank-not-open-yet": "Retiro de esta cantidad de moneda aún no está abierto",
    "systemHint-plsFillIn": "Por favor complete toda la información",
    "systemHint-requestSuccessful": "Solicitud exitosa",
    "systemHint-operation_successful": "Operación exitosa",
    "systemHint-set_successful": "Configuración exitosa",
    "systemHint-missing_params-or-val-error": "Parámetros faltantes o error de valor",
    "systemHint-limit_trade_price_must_greater": "El precio límite de comercio debe ser mayor que 0",
    "systemHint-buy-stop_loss_price_higher_than_opening_price": "El precio de stop-loss para compra (long) no debe ser mayor que el precio de apertura",
    "systemHint-buy-stop_profit_price_higher_than_opening_price": "El precio de stop-profit para compra (long) no debe ser mayor que el precio de apertura",
    "systemHint-sell-stop_loss_price_lower_than_opening_price": "El precio de stop-loss para venta (short) no debe ser menor que el precio de apertura",
    "systemHint-sell-stop_profit_price_lower_than_opening_price": "El precio de stop-profit para venta (short) no debe ser menor que el precio de apertura",
    "systemHint-code_send_succesful": "Código de verificación enviado con éxito",
    "systemHint-pls_register_login_real_account": "Por favor regístrese o inicie sesión en una cuenta real",
    "systemHint-successful_exit": "Salida exitosa",
    "popupHint-set_up_collection_account": "Por favor, configure primero una cuenta de recepción",
    "popupHint-confirm_to_delete": "¿Está seguro de que desea eliminar?",
    "popupHint-whether_return_it": "Si debe devolverlo",
    "mine-availableAssets": "Activos disponibles",
    "mine-account_details": "Detalles de la cuenta",
    "mine-deposit": "Depósito",
    "mine-withdraw": "Retiro",
    "mine-billDetails": "Detalles de la factura",
    "mine-demo_return_simulated_account": "Volver a la cuenta simulada",
    "mine-wallet": "Cartera",
    "mine-verifyName": "Verificación de nombre real",
    "mine-inviteFriend": "Invitar amigos",
    "mine-changePassword": "Cambiar contraseña",
    "mine-onlineSerive": "Servicio en línea",
    "mine-complaintEmail": "Correo electrónico de quejas",
    "mine-announcement": "Anuncio",
    "mine-establishment": "Establecimiento",
    "mine-other": "Otro",
    "mine-change_theme": "Cambiar tema",
    "dialog-title": "Nota",
    "dialog-logout-conetnt": "¿Está seguro de que desea cerrar sesión?",
    "announce-text": "Por razones políticas, no se ofrecen servicios en Corea del Norte, Israel, China, Vanuatu y Cuba.",
    "announce-text-demo": "(FBS Solo para fines de prueba)",
    "verify-text-upload": "Por favor, cargue el anverso y reverso de su pasaporte/identificación",
    "verify-front": "Anverso",
    "verify-front-desc": "Cargue el anverso de su pasaporte/certificado",
    "verify-back": "Reverso",
    "verify-back-desc": "Cargue el reverso de su pasaporte/certificado",
    "verify-text-info": "Por favor, complete sus datos personales",
    "verify-name": "Nombre",
    "verify-enterName": "Por favor, ingrese su nombre",
    "verify-passportId": "Número de pasaporte/ID",
    "verify-enterPassportId": "Por favor, ingrese su número de pasaporte/ID",
    "verify-infoAuth": "Autenticación",
    "wallet-bind-bank": "Vincular tarjeta bancaria",
    "wallet-bind-currency-address": "Vincular dirección de moneda digital",
    "bind-bank-name": "Nombre del banco",
    "bind-bank-address": "Dirección del banco",
    "bind-bank-swift": "SWIFT",
    "bind-bank-payee": "Beneficiario",
    "bind-bank-payee-account": "Cuenta del beneficiario",
    "bind-currency": "Moneda",
    "bind-wallet-address": "Dirección de la cartera",
    "deposit-digitai-currency": "Moneda digital",
    "deposit-bank-card": "Tarjeta bancaria",
    "deposit-select_network": "Seleccionar red",
    "deposit-coin-number": "Número de monedas",
    "deposit-coin-placeholder": "Por favor, ingrese el número de monedas a depositar",
    "doposit-upload-cert": "Subir certificado",
    "deposit-record": "Registro de depósito",
    "deposit-recharge_amount": "Monto de recarga",
    "deposit-recharge-placeholder": "Por favor, ingrese el monto de la recarga",
    "deposit-recharge_type": "Tipo",
    "deposit-recharge_unit": "Unidad",
    "deposit-recharge_status": "Estado",
    "deposit-receipt_currency": "Moneda recibida",
    "withdraw-coin_address": "Dirección de retiro de monedas",
    "withdraw-coin_amount": "Monto",
    "withdraw-handling_fee": "Cargo por manejo",
    "withdraw-expected": "Se espera que",
    "withdraw-btn-submit": "Solicitar retiro",
    "withdraw-coin_withdrawal_record": "Registro de retiro de monedas",
    "withdraw-num_of_receipt": "Número de recibos",
    "withdraw-select_addess": "Por favor, seleccione una dirección",
    "order-position_holding": "Posición en curso",
    "order-pending_orders": "Órdenes pendientes",
    "order-history": "Historial",
    "order-profit_loss": "Ganancia y pérdida",
    "order-current_margin": "Margen actual",
    "order-risk_rate": "Tasa de riesgo",
    "order-order_id": "ID de pedido",
    "order-total_profit_loss": "Ganancia y pérdida total",
    "order-order_details": "Detalles del pedido",
    "order-margin": "Margen",
    "order-take_profit": "Tomar ganancias",
    "order-set_loss": "Fijar pérdida",
    "order-set_TPSL": "Configurar TP/SL",
    "order-close_position": "Cerrar posición",
    "order-cancel_order": "Cancelar pedido",
    "order-select_period": "Seleccionar período",
    "order-btn_rise": "Subir",
    "order-btn_fall": "Bajar",
    "order-estimated_return": "Retorno estimado",
    "order-purchase_amount": "Monto de compra",
    "search-search_product": "Buscar producto",
    "search-search_result": "Resultados de búsqueda",
    "trade-market_price": "Precio de mercado",
    "trade-price": "Precio",
    "trade-quick_trade": "Comercio rápido",
    "trade-please_enter_price": "Por favor, ingrese el precio",
    "trade-each_lots": "Cada lote",
    "trade-estimated_handling_fee": "Cargo por manejo estimado",
    "trade-estimated_margin": "Margen estimado",
    "trade-lots_text": "Lotes (paso: 0,01)",
    "trade-order_page": "Página de pedido",
    "trade-your_order_has_been_confirmed": "Su pedido ha sido confirmado",
    "trade-multiplier": "Multiplicador",
    "accHistory-financial_records": "Registros financieros",
    "accHistory-records": "Registros",

      'Res_Msg1': 'Por favor, ingrese el correo electrónico',
    'Res_Msg2': 'Formato de correo electrónico no válido',
    'Res_Msg3': 'Por favor, ingrese la contraseña',
    'Res_Msg4': 'La contraseña y la confirmación de la contraseña no coinciden',
    'Res_Msg5': 'La contraseña debe tener al menos 6 caracteres',
    'Res_Msg6': 'Código de verificación incorrecto',
    'Res_Msg7': 'Por favor, ingrese el código de verificación',
    'Res_su': 'Registro exitoso',
    'Login_su': 'Inicio de sesión exitoso',
    'Loading': 'No hay más datos',
    'SDealCont_TopName': 'Entrega',
    'SDealCont_L1': 'Precio de compra',
    'SDealCont_L2': 'Precio actual',
    'SDealCont_L3': 'Dirección',
    'SDealCont_L4': 'Cantidad',
    'SDealCont_L5': 'Hora de entrega',
    'SDealCont_btn': 'Cerrar',
    'SDealCont_L6': 'Comprar largo',
    'SDealCont_L7': 'Vender corto',
    'SDealCont_L8': 'Comprar',
    'SDealCont_L9': 'Vender',
    'verify-verified': 'Verificación exitosa',
    'verify-notVerified': 'Verificación fallida'
}
