export default {
    'app-title': 'FBS',
    'app-title-2': 'Unsaleable',
    'app-title-language': '語言',
    'title-detail': '詳情',
    'title-welcome_to_use': '歡迎使用',

    //tabBar
    'tabbar-market': '行情',
    'tabbar-order': '訂單',
    'tabbar-news': '新聞',
    'tabbar-mine': '個人中心',
    'public-night_mode': '夜間模式',
    'public-noRecord': '暫無數據',
    'public-text-cancel': '取消',
    'public-text-confirm': '確認',
    'public-text-balance': '餘額',
    'public-text-date': '時間',
    'public-text-completed': '已完成',
    'public-text-invalid': '無效',
    'public-text-unchecked': '未審核',
    'public-text-remarks': '備註',
    'public-text-loading': '加載中',
    'public-text-loose-to-refresh': '鬆開刷新...',
    'public-text-buy': '買入',
    'public-text-sell': '賣出',
    'public-text-close': '休市',
    'public-text-lots_1': '手',
    'public-text-lots': '手數',
    'public-text-available': '可用',
    'public-text-freeze': '凍結',
    'public-text-scroll_down_refresh': '下拉即可刷新...',
    'public-btn-submit': '提交',
    'public-btn-add': '添加',
    'public-btn-copy': '複製',
    'public-btn-delete': '刪除',
    'public-btn-copyLink': '複製邀請鏈接',
    'public-btn-send': '發送',
    'public-btn-download': '下載',
    'public-btn-resetPassword': '重置密碼',
    'public-btn-confirmation': '確認',
    'public-btn-accept': '接受',
    'public-enterEmail': '輸入您的電子郵箱',
    'public-password': '密碼',
    'public-retypePassword': '重新輸入密碼',
    'public-inviteCode': '邀請碼',
    'public-verifyCode': '驗證碼',
    'login-newUser': '新用戶?',
    'login-joinNow': '立即加入',
    'login-forgotPassword': '忘記密碼',
    'login-btn-login': '登錄',
    'login-btn-demoAcc': '無需開戶，立即創建模擬賬戶進行交易!',
    'login-enterEmail': '請輸入您的電子郵箱',
    'login-enterPassword': '請輸入您的密碼',
    'regsiter-btn-register': '註冊',
    'regsiter-enterInviteCode': '請輸入邀請碼',
    'regsiter-enterVerityCode': '請輸入驗證碼',
    'logout-title': '退出登錄',
    'systemHint-reset_successful': '重置成功',
    'systemHint-incorrectVerityCode': '驗證碼錯誤',
    'systemHint-passwordsDoNotMatch': '密碼不匹配',
    'systemHint-password_error': '密碼錯誤',
    'systemHint-login_connot_find_user': '找不到該用戶',
    'systemHint-copySuccess': '複製成功',
    'systemHint-plsCompleted': '請填寫完整',
    'systemHint-pwdInconsistent': '密碼不一致',
    'systemHint-incorrectVerifyCode': '驗證碼錯誤',
    'systemHint-changeSuccess': '修改成功',
    'systemHint-plsSubmit': '請提交完整信息',
    'systemHint-binb-bank-not-open-yet': '該貨幣的提現尚未開放',
    'systemHint-plsFillIn': '請填寫完整',
    'systemHint-requestSuccessful': '請求成功',
    'systemHint-operation_successful': '操作成功',
    'systemHint-set_successful': '設置成功',
    'systemHint-missing_params-or-val-error': '缺少參數或傳值錯誤',
    'systemHint-limit_trade_price_must_greater': '限價交易價格必須大於0',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': '買入(多)止損價不能高於開盤價',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': '買入(多)止盈價不能高於開盤價',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': '賣出(空)止損價不能低於開盤價',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': '賣出(空)止盈價不能低於開盤價',
    'systemHint-code_send_succesful': '驗證碼發送成功',
    'systemHint-pls_register_login_real_account': '請註冊或登錄真實賬戶',
    'systemHint-successful_exit': '登出成功',
    'popupHint-set_up_collection_account': '請先設置收款賬戶',
    'popupHint-confirm_to_delete': '確定刪除嗎？',
    'popupHint-whether_return_it': '是否退回？',
    'mine-availableAssets': '可用資產',
    'mine-account_details': '賬戶詳情',
    'mine-deposit': '入金',
    'mine-withdraw': '出金',
    'mine-billDetails': '賬單詳情',
    'mine-demo_return_simulated_account': '重置模擬賬戶',
    'mine-wallet': '錢包',
    'mine-verifyName': '實名認證',
    'mine-inviteFriend': '邀請好友',
    'mine-changePassword': '修改密碼',
    'mine-onlineSerive': '聯係客服',
    'mine-complaintEmail': '投訴郵箱',
    'mine-announcement': '公告',
    'mine-establishment': '設置',
    'mine-other': '其他',
    'mine-change_theme': '更換主題',
    'dialog-title': '提示',
    'dialog-logout-conetnt': '確定要登出嗎？',
    'announce-text': '由於政策原因，暫不向朝鮮、以色列、中國、瓦努阿圖和古巴提供服務。',
    'announce-text-demo': '（演示僅用於測試）',
    'verify-text-upload': '請上傳護照/身份證的正反面',
    'verify-front': '正面',
    'verify-front-desc': '上傳護照/證件的正面',
    'verify-back': '反面',
    'verify-back-desc': '上傳護照/證件的反面',
    'verify-text-info': '請完善您的個人信息',
    'verify-name': '姓名',
    'verify-enterName': '請輸入您的姓名',
    'verify-passportId': '護照/身份證號',
    'verify-enterPassportId': '請輸入您的護照/身份證號',
    'verify-infoAuth': '審核中',
    'wallet-bind-bank': '綁定銀行卡',
    'wallet-bind-currency-address': '綁定數字貨幣地址',
    'bind-bank-name': '貨幣',
    'bind-bank-address': '提幣地址',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': '收款人',
    'bind-bank-payee-account': '收款人賬戶',
    'bind-currency': '貨幣',
    'bind-wallet-address': '錢包地址',
    'deposit-digitai-currency': '數字貨幣',
    'deposit-bank-card': '銀行卡',
    'deposit-select_network': '選擇網絡',
    'deposit-coin-number': '充幣數量',
    'deposit-coin-placeholder': '請輸入充值幣數',
    'doposit-upload-cert': '上傳證書',
    'deposit-record': '入金記錄',
    'deposit-recharge_amount': '充值金額',
    'deposit-recharge-placeholder': '請輸入充值金額',
    'deposit-recharge_type': '類型',
    'deposit-recharge_unit': '單位',
    'deposit-recharge_status': '狀態',
    'deposit-receipt_currency': '收款貨幣',
    'withdraw-coin_address': '提幣地址',
    'withdraw-coin_amount': '數量',
    'withdraw-handling_fee': '手續費',
    'withdraw-expected': '預計到賬時間',
    'withdraw-btn-submit': '提交提現',
    'withdraw-coin_withdrawal_record': '提幣記錄',
    'withdraw-num_of_receipt': '收款數量',
    'withdraw-select_addess': '請選擇提幣地址',
    'order-position_holding': '持倉',
    'order-pending_orders': '掛單',
    'order-history': '歷史',
    'order-profit_loss': '總盈虧',
    'order-current_margin': '當前保證金',
    'order-risk_rate': '風險率',
    'order-order_id': '訂單ID',
    'order-total_profit_loss': '總盈虧',
    'order-order_details': '訂單詳情',
    'order-margin': '保證金',
    'order-take_profit': '止盈',
    'order-set_loss': '止損',
    'order-set_TPSL': '修改 TP/SL',
    'order-close_position': '平倉',
    'order-cancel_order': '取消訂單',
    'order-select_period': '選擇周期',
    'order-btn_rise': '漲',
    'order-btn_fall': '跌',
    'order-estimated_return': '預估收益',
    'order-purchase_amount': '購買金額',
    'search-search_product': '搜索產品',
    'search-search_result': '搜索結果',
    'trade-market_price': '市價',
    'trade-price': '價格',
    'trade-quick_trade': '秒合約',
    'trade-please_enter_price': '請輸入價格',
    'trade-each_lots': '每手',
    'trade-estimated_handling_fee': '預計手續費',
    'trade-estimated_margin': '預計保證金',
    'trade-lots_text': '買入數量(Step:0.01)',
    'trade-order_page': '訂單頁面',
    'trade-your_order_has_been_confirmed': '您的訂單已確認',
    'trade-multiplier': '倍數',
    'accHistory-financial_records': '財務記錄',
    'accHistory-records': '記錄',

    'Res_Msg1': '請輸入電郵',
    'Res_Msg2': '電郵格式有誤',
    'Res_Msg3': '請輸入密碼',
    'Res_Msg4': '密碼與重複密碼不同',
    'Res_Msg5': '密碼字符長度不少於6位數',
    'Res_Msg6': '驗證碼輸入有誤',
    'Res_Msg7': '請輸入驗證碼',
    'Res_su': '註冊成功',
    'Login_su': '登入成功',
    'Loading': '沒有更多了',
    'SDealCont_TopName': '交割',
    'SDealCont_L1': '購買價',
    'SDealCont_L2': '現價',
    'SDealCont_L3': '方向',
    'SDealCont_L4': '數量',
    'SDealCont_L5': '交割時間',
    'SDealCont_btn': '關閉',
    'SDealCont_L6': '開多',
    'SDealCont_L7': '開少',
    'SDealCont_L8': '買入',
    'SDealCont_L9': '賣出',
    'verify-verified': '審核成功',
    'verify-notVerified': '審核失敗'
}
