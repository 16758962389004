export default {
    'app-title': 'FBS',
    'app-title-2': 'Nicht verkäuflich',
    'app-title-language': 'Sprache',
    'title-detail': 'Detail',
    'title-welcome_to_use': 'Willkommen zur Nutzung',

    //tabBar
    'tabbar-market': 'Markt',
    'tabbar-order': 'Bestellung',
    'tabbar-news': 'Nachrichten',
    'tabbar-mine': 'Mein Bereich',
    'public-night_mode': 'Nachtmodus',
    'public-noRecord': 'Noch keine Daten',
    'public-text-cancel': 'Abbrechen',
    'public-text-confirm': 'Bestätigen',
    'public-text-balance': 'Guthaben',
    'public-text-date': 'Datum',
    'public-text-completed': 'Abgeschlossen',
    'public-text-invalid': 'Ungültig',
    'public-text-unchecked': 'Nicht überprüft',
    'public-text-remarks': 'Bemerkung',
    'public-text-loading': 'Laden',
    'public-text-loose-to-refresh': 'Lass los, um zu aktualisieren...',
    'public-text-buy': 'Kaufen',
    'public-text-sell': 'Verkaufen',
    'public-text-close': 'Geschlossen',
    'public-text-lots_1': 'Stücke',
    'public-text-lots': 'Stücke',
    'public-text-available': 'Verfügbar',
    'public-text-freeze': 'Gefroren',
    'public-text-scroll_down_refresh': 'Nach unten scrollen, um zu aktualisieren...',
    'public-btn-submit': 'Absenden',
    'public-btn-add': 'Hinzufügen',
    'public-btn-copy': 'Kopieren',
    'public-btn-delete': 'Löschen',
    'public-btn-copyLink': 'Einladungslink kopieren',
    'public-btn-send': 'Senden',
    'public-btn-download': 'HERUNTERLADEN',
    'public-btn-resetPassword': 'Passwort zurücksetzen',
    'public-btn-confirmation': 'Bestätigung',
    'public-btn-accept': 'Akzeptieren',
    'public-enterEmail': 'Geben Sie Ihre E-Mail-Adresse ein',
    'public-password': 'Passwort',
    'public-retypePassword': 'Passwort erneut eingeben',
    'public-inviteCode': 'Einladungscode',
    'public-verifyCode': 'Bestätigungscode',
    'login-newUser': 'Neuer Benutzer?',
    'login-joinNow': 'Jetzt beitreten',
    'login-forgotPassword': 'Passwort vergessen',
    'login-btn-login': 'Anmelden',
    'login-btn-demoAcc': 'Kein Konto erforderlich, erstellen Sie jetzt ein Demokonto, um zu handeln!',
    'login-enterEmail': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
    'login-enterPassword': 'Bitte geben Sie Ihr Passwort ein',
    'regsiter-btn-register': 'Registrieren',
    'regsiter-enterInviteCode': 'Bitte geben Sie den Einladungscode ein',
    'regsiter-enterVerityCode': 'Bitte geben Sie den Bestätigungscode ein',
    'logout-title': 'Abmelden',
    'systemHint-reset_successful': 'Zurücksetzung erfolgreich',
    'systemHint-incorrectVerityCode': 'Falscher Bestätigungscode',
    'systemHint-passwordsDoNotMatch': 'Passwörter stimmen nicht überein',
    'systemHint-password_error': 'Passwortfehler',
    'systemHint-login_connot_find_user': 'Benutzer nicht gefunden',
    'systemHint-copySuccess': 'Erfolgreich kopiert',
    'systemHint-plsCompleted': 'Bitte vervollständigen',
    'systemHint-pwdInconsistent': 'Passwort inkonsistent',
    'systemHint-incorrectVerifyCode': 'Falscher Bestätigungscode',
    'systemHint-changeSuccess': 'Änderung erfolgreich',
    'systemHint-plsSubmit': 'Bitte vollständige Informationen einreichen',
    'systemHint-binb-bank-not-open-yet': 'Abhebung dieses Währungsbetrags noch nicht geöffnet',
    'systemHint-plsFillIn': 'Bitte vollständig ausfüllen',
    'systemHint-requestSuccessful': 'Anfrage erfolgreich',
    'systemHint-operation_successful': 'Operation erfolgreich',
    'systemHint-set_successful': 'Erfolgreich eingestellt',
    'systemHint-missing_params-or-val-error': 'Fehlende Parameter oder Wertfehler',
    'systemHint-limit_trade_price_must_greater': 'Limit-Handelspreis muss größer als 0 sein',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'Kauf (Long) Stop-Loss-Preis darf nicht höher als der Eröffnungspreis sein',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'Kauf (Long) Stop-Gewinn-Preis darf nicht höher als der Eröffnungspreis sein',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'Verkauf (Short) Stop-Loss-Preis darf nicht niedriger als der Eröffnungspreis sein',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'Verkauf (Short) Stop-Gewinn-Preis darf nicht niedriger als der Eröffnungspreis sein',
    'systemHint-code_send_succesful': 'Bestätigungscode erfolgreich gesendet',
    'systemHint-pls_register_login_real_account': 'Bitte registrieren oder in ein echtes Konto einloggen',
    'systemHint-successful_exit': 'Erfolgreich beendet',
    'popupHint-set_up_collection_account': 'Bitte richten Sie zuerst ein Sammelkonto ein',
    'popupHint-confirm_to_delete': 'Sind Sie sicher, dass Sie löschen möchten?',
    'popupHint-whether_return_it': 'Ob zurückgeben',
    'mine-availableAssets': 'Verfügbare Vermögenswerte',
    'mine-account_details': 'Kontodetails',
    'mine-deposit': 'Einzahlung',
    'mine-withdraw': 'Abhebung',
    'mine-billDetails': 'Rechnungsdetails',
    'mine-demo_return_simulated_account': 'Zum simulierten Konto zurückkehren',
    'mine-wallet': 'Wallet',
    'mine-verifyName': 'Echtname-Verifizierung',
    'mine-inviteFriend': 'Freunde einladen',
    'mine-changePassword': 'Passwort ändern',
    'mine-onlineSerive': 'Online-Service',
    'mine-complaintEmail': 'Beschwerde-E-Mail',
    'mine-announcement': 'Ankündigung',
    'mine-establishment': 'Gründung',
    'mine-other': 'Andere',
    'mine-change_theme': 'Thema ändern',
    'dialog-title': 'Hinweis',
    'dialog-logout-conetnt': 'Sind Sie sicher, dass Sie sich abmelden möchten?',
    'announce-text': 'Aus politischen Gründen werden keine Dienstleistungen in Nordkorea, Israel, China, Vanuatu und Kuba angeboten.',
    'announce-text-demo': '(FBS Nur zu Testzwecken)',
    'verify-text-upload': 'Bitte laden Sie die Vorder- und Rückseite Ihres Passes/Personalausweises hoch',
    'verify-front': 'Vorderseite',
    'verify-front-desc': 'Laden Sie die Vorderseite Ihres Passes/Zertifikats hoch',
    'verify-back': 'Rückseite',
    'verify-back-desc': 'Laden Sie die Rückseite Ihres Passes/Zertifikats hoch',
    'verify-text-info': 'Bitte vervollständigen Sie Ihre persönlichen Daten',
    'verify-name': 'Name',
    'verify-enterName': 'Bitte geben Sie Ihren Namen ein',
    'verify-passportId': 'Passnummer/ID-Nummer',
    'verify-enterPassportId': 'Bitte geben Sie Ihre Passnummer/ID-Nummer ein',
    'verify-infoAuth': 'Prüfung',
    'wallet-bind-bank': 'Bankkarte binden',
    'wallet-bind-currency-address': 'Digitale Währungsadresse binden',
    'bind-bank-name': 'Bankname',
    'bind-bank-address': 'Bankadresse',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'Empfänger',
    'bind-bank-payee-account': 'Empfängerkonto',
    'bind-currency': 'Währung',
    'bind-wallet-address': 'Wallet-Adresse',
    'deposit-digitai-currency': 'Digitale Währung',
    'deposit-bank-card': 'Bankkarte',
    'deposit-select_network': 'Netzwerk auswählen',
    'deposit-coin-number': 'Anzahl der Münzen',
    'deposit-coin-placeholder': 'Bitte geben Sie die Anzahl der einzuzahlenden Münzen ein',
    'doposit-upload-cert': 'Zertifikat hochladen',
    'deposit-record': 'Einzahlungsprotokoll',
    'deposit-recharge_amount': 'Aufladebetrag',
    'deposit-recharge-placeholder': 'Bitte geben Sie den Aufladebetrag ein',
    'deposit-recharge_type': 'Typ',
    'deposit-recharge_unit': 'Einheit',
    'deposit-recharge_status': 'Status',
    'deposit-receipt_currency': 'Empfangswährung',
    'withdraw-coin_address': 'Adresse für Münzabhebungen',
    'withdraw-coin_amount': 'Betrag',
    'withdraw-handling_fee': 'Bearbeitungsgebühr',
    'withdraw-expected': 'Es wird erwartet, dass',
    'withdraw-btn-submit': 'Abhebung beantragen',
    'withdraw-coin_withdrawal_record': 'Münzabhebungsprotokoll',
    'withdraw-num_of_receipt': 'Anzahl der Belege',
    'withdraw-select_addess': 'Bitte wählen Sie eine Adresse aus',
    'order-position_holding': 'Position halten',
    'order-pending_orders': 'Ausstehende Bestellungen',
    'order-history': 'Historie',
    'order-profit_loss': 'Gewinn und Verlust',
    'order-current_margin': 'Aktuelle Marge',
    'order-risk_rate': 'Risikoteuerung',
    'order-order_id': 'Bestell-ID',
    'order-total_profit_loss': 'Gesamtgewinn und -verlust',
    'order-order_details': 'Bestelldetails',
    'order-margin': 'Marge',
    'order-take_profit': 'Gewinnmitnahme',
    'order-set_loss': 'Verlustbegrenzung',
    'order-set_TPSL': 'TP/SL festlegen',
    'order-close_position': 'Position schließen',
    'order-cancel_order': 'Bestellung stornieren',
    'order-select_period': 'Zeitraum auswählen',
    'order-btn_rise': 'Steigen',
    'order-btn_fall': 'Fallen',
    'order-estimated_return': 'Geschätzte Rendite',
    'order-purchase_amount': 'Kaufbetrag',
    'search-search_product': 'Produkt suchen',
    'search-search_result': 'Suchergebnisse',
    'trade-market_price': 'Marktpreis',
    'trade-price': 'Preis',
    'trade-quick_trade': 'Schnellhandel',
    'trade-please_enter_price': 'Bitte Preis eingeben',
    'trade-each_lots': 'Jedes Los',
    'trade-estimated_handling_fee': 'Geschätzte Bearbeitungsgebühr',
    'trade-estimated_margin': 'Geschätzte Marge',
    'trade-lots_text': 'Losse (Schritt: 0,01)',
    'trade-order_page': 'Bestellseite',
    'trade-your_order_has_been_confirmed': 'Ihre Bestellung wurde bestätigt',
    'trade-multiplier': 'Multiplikator',
    'accHistory-financial_records': 'Finanzaufzeichnungen',
    'accHistory-records': 'Aufzeichnungen',

     'Res_Msg1': 'Bitte E-Mail eingeben',
    'Res_Msg2': 'Ungültiges E-Mail-Format',
    'Res_Msg3': 'Bitte Passwort eingeben',
    'Res_Msg4': 'Passwort und Passwortwiederholung stimmen nicht überein',
    'Res_Msg5': 'Das Passwort muss mindestens 6 Zeichen lang sein',
    'Res_Msg6': 'Falsche Eingabe des Bestätigungscodes',
    'Res_Msg7': 'Bitte Bestätigungscode eingeben',
    'Res_su': 'Registrierung erfolgreich',
    'Login_su': 'Anmeldung erfolgreich',
    'Loading': 'Keine weiteren Daten',
    'SDealCont_TopName': 'Lieferung',
    'SDealCont_L1': 'Kaufpreis',
    'SDealCont_L2': 'Aktueller Preis',
    'SDealCont_L3': 'Richtung',
    'SDealCont_L4': 'Menge',
    'SDealCont_L5': 'Lieferzeit',
    'SDealCont_btn': 'Schließen',
    'SDealCont_L6': 'Long kaufen',
    'SDealCont_L7': 'Short verkaufen',
    'SDealCont_L8': 'Kaufen',
    'SDealCont_L9': 'Verkaufen',
    'verify-verified': 'Überprüfung erfolgreich',
    'verify-notVerified': 'Überprüfung fehlgeschlagen'
}
