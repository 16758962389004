export default {
    'app-title': 'เดโม',
    'app-title-2': 'ไม่สามารถขายได้',
    'app-title-language': 'ภาษา',
    'title-detail': 'รายละเอียด',
    'title-welcome_to_use': 'ยินดีต้อนรับ',

    //tabBar
    'tabbar-market': 'ตลาด',
    'tabbar-order': 'คำสั่งซื้อ',
    'tabbar-news': 'ข่าวสาร',
    'tabbar-mine': 'บัญชีของฉัน',
    'public-night_mode': 'โหมดกลางคืน',
    'public-noRecord': 'ยังไม่มีข้อมูล',
    'public-text-cancel': 'ยกเลิก',
    'public-text-confirm': 'ยืนยัน',
    'public-text-balance': 'ยอดคงเหลือ',
    'public-text-date': 'วันที่',
    'public-text-completed': 'เสร็จสิ้น',
    'public-text-invalid': 'ไม่ถูกต้อง',
    'public-text-unchecked': 'ยังไม่ตรวจสอบ',
    'public-text-remarks': 'หมายเหตุ',
    'public-text-loading': 'กำลังโหลด',
    'public-text-loose-to-refresh': 'เลื่อนเพื่อรีเฟรช...',
    'public-text-buy': 'ซื้อ',
    'public-text-sell': 'ขาย',
    'public-text-close': 'ปิด',
    'public-text-lots_1': 'ล็อต',
    'public-text-lots': 'ล็อต',
    'public-text-available': 'พร้อมใช้งาน',
    'public-text-freeze': 'แช่แข็ง',
    'public-text-scroll_down_refresh': 'เลื่อนลงเพื่อรีเฟรช...',
    'public-btn-submit': 'ส่ง',
    'public-btn-add': 'เพิ่ม',
    'public-btn-copy': 'คัดลอก',
    'public-btn-delete': 'ลบ',
    'public-btn-copyLink': 'คัดลอกลิงก์เชิญ',
    'public-btn-send': 'ส่ง',
    'public-btn-download': 'ดาวน์โหลด',
    'public-btn-resetPassword': 'รีเซ็ตรหัสผ่าน',
    'public-btn-confirmation': 'ยืนยัน',
    'public-btn-accept': 'ยอมรับ',
    'public-enterEmail': 'กรุณากรอกอีเมลของคุณ',
    'public-password': 'รหัสผ่าน',
    'public-retypePassword': 'พิมพ์รหัสผ่านอีกครั้ง',
    'public-inviteCode': 'รหัสเชิญ',
    'public-verifyCode': 'รหัสยืนยัน',
    'login-newUser': 'ผู้ใช้ใหม่?',
    'login-joinNow': 'เข้าร่วมตอนนี้',
    'login-forgotPassword': 'ลืมรหัสผ่าน',
    'login-btn-login': 'เข้าสู่ระบบ',
    'login-btn-demoAcc': 'ไม่ต้องเปิดบัญชี, สร้างบัญชีเดโมเพื่อเริ่มเทรดตอนนี้!',
    'login-enterEmail': 'กรุณากรอกที่อยู่อีเมลของคุณ',
    'login-enterPassword': 'กรุณากรอกรหัสผ่านของคุณ',
    'regsiter-btn-register': 'ลงทะเบียน',
    'regsiter-enterInviteCode': 'กรุณากรอกรหัสเชิญ',
    'regsiter-enterVerityCode': 'กรุณากรอกรหัสยืนยัน',
    'logout-title': 'ออกจากระบบ',
    'systemHint-reset_successful': 'รีเซ็ตสำเร็จ',
    'systemHint-incorrectVerityCode': 'รหัสยืนยันไม่ถูกต้อง',
    'systemHint-passwordsDoNotMatch': 'รหัสผ่านไม่ตรงกัน',
    'systemHint-password_error': 'รหัสผ่านผิด',
    'systemHint-login_connot_find_user': 'ไม่พบผู้ใช้',
    'systemHint-copySuccess': 'คัดลอกสำเร็จ',
    'systemHint-plsCompleted': 'กรุณากรอกให้ครบ',
    'systemHint-pwdInconsistent': 'รหัสผ่านไม่ตรงกัน',
    'systemHint-incorrectVerifyCode': 'รหัสยืนยันไม่ถูกต้อง',
    'systemHint-changeSuccess': 'การเปลี่ยนแปลงสำเร็จ',
    'systemHint-plsSubmit': 'กรุณาส่งข้อมูลให้ครบถ้วน',
    'systemHint-binb-bank-not-open-yet': 'การถอนเงินของสกุลเงินนี้ยังไม่เปิดใช้งาน',
    'systemHint-plsFillIn': 'กรุณากรอกให้ครบถ้วน',
    'systemHint-requestSuccessful': 'คำขอสำเร็จ',
    'systemHint-operation_successful': 'ดำเนินการสำเร็จ',
    'systemHint-set_successful': 'ตั้งค่าเสร็จสิ้น',
    'systemHint-missing_params-or-val-error': 'ขาดพารามิเตอร์หรือค่าผิดพลาด',
    'systemHint-limit_trade_price_must_greater': 'ราคาการซื้อขายแบบจำกัดต้องมากกว่า 0',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'ราคาหยุดขาดทุนการซื้อ (Long) ไม่สามารถสูงกว่าราคาเปิด',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'ราคาหยุดทำกำไรการซื้อ (Long) ไม่สามารถสูงกว่าราคาเปิด',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'ราคาหยุดขาดทุนการขาย (Short) ไม่สามารถต่ำกว่าราคาเปิด',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'ราคาหยุดทำกำไรการขาย (Short) ไม่สามารถต่ำกว่าราคาเปิด',
    'systemHint-code_send_succesful': 'ส่งรหัสยืนยันสำเร็จ',
    'systemHint-pls_register_login_real_account': 'กรุณาลงทะเบียนหรือเข้าสู่ระบบบัญชีจริง',
    'systemHint-successful_exit': 'ออกจากระบบสำเร็จ',
    'popupHint-set_up_collection_account': 'กรุณาตั้งค่าบัญชีการเก็บเงินก่อน',
    'popupHint-confirm_to_delete': 'คุณแน่ใจหรือไม่ว่าต้องการลบ?',
    'popupHint-whether_return_it': 'ต้องการกลับไปหรือไม่',
    'mine-availableAssets': 'สินทรัพย์ที่พร้อมใช้งาน',
    'mine-account_details': 'รายละเอียดบัญชี',
    'mine-deposit': 'ฝาก',
    'mine-withdraw': 'ถอน',
    'mine-billDetails': 'รายละเอียดบิล',
    'mine-demo_return_simulated_account': 'กลับไปยังบัญชีจำลอง',
    'mine-wallet': 'กระเป๋าเงิน',
    'mine-verifyName': 'ตรวจสอบชื่อจริง',
    'mine-inviteFriend': 'เชิญเพื่อน',
    'mine-changePassword': 'เปลี่ยนรหัสผ่าน',
    'mine-onlineSerive': 'บริการออนไลน์',
    'mine-complaintEmail': 'อีเมลสำหรับการร้องเรียน',
    'mine-announcement': 'ประกาศ',
    'mine-establishment': 'จัดตั้ง',
    'mine-other': 'อื่นๆ',
    'mine-change_theme': 'เปลี่ยนธีม',
    'dialog-title': 'แจ้งเตือน',
    'dialog-logout-conetnt': 'คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ?',
    'announce-text': 'เนื่องจากข้อกำหนดด้านนโยบาย, บริการไม่สามารถให้บริการในเกาหลีเหนือ, อิสราเอล, จีน, วานูอาตู และคิวบา.',
    'announce-text-demo': '(เดโม สำหรับการทดสอบเท่านั้น)',
    'verify-text-upload': 'กรุณาอัปโหลดด้านหน้าและด้านหลังของพาสปอร์ต/บัตรประจำตัว',
    'verify-front': 'ด้านหน้า',
    'verify-front-desc': 'อัปโหลดด้านหน้าของพาสปอร์ต/ใบรับรองของคุณ',
    'verify-back': 'ด้านหลัง',
    'verify-back-desc': 'อัปโหลดด้านหลังของพาสปอร์ต/ใบรับรองของคุณ',
    'verify-text-info': 'กรุณากรอกข้อมูลส่วนบุคคลของคุณให้ครบถ้วน',
    'verify-name': 'ชื่อ',
    'verify-enterName': 'กรุณากรอกชื่อของคุณ',
    'verify-passportId': 'หมายเลขพาสปอร์ต/ID',
    'verify-enterPassportId': 'กรุณากรอกหมายเลขพาสปอร์ต/ID ของคุณ',
    'verify-infoAuth': 'การตรวจสอบ',
    'wallet-bind-bank': 'เชื่อมโยงบัตรธนาคาร',
    'wallet-bind-currency-address': 'เชื่อมโยงที่อยู่สกุลเงินดิจิทัล',
    'bind-bank-name': 'ชื่อธนาคาร',
    'bind-bank-address': 'ที่อยู่ธนาคาร',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'ผู้รับเงิน',
    'bind-bank-payee-account': 'บัญชีผู้รับเงิน',
    'bind-currency': 'สกุลเงิน',
    'bind-wallet-address': 'ที่อยู่กระเป๋าเงิน',
    'deposit-digitai-currency': 'สกุลเงินดิจิทัล',
    'deposit-bank-card': 'บัตรธนาคาร',
    'deposit-select_network': 'เลือกเครือข่าย',
    'deposit-coin-number': 'จำนวนเหรียญ',
    'deposit-coin-placeholder': 'กรุณากรอกจำนวนเหรียญที่ต้องการฝาก',
    'doposit-upload-cert': 'อัปโหลดใบรับรอง',
    'deposit-record': 'บันทึกการฝาก',
    'deposit-recharge_amount': 'จำนวนเงินฝาก',
    'deposit-recharge-placeholder': 'กรุณากรอกจำนวนเงินฝาก',
    'deposit-recharge_type': 'ประเภท',
    'deposit-recharge_unit': 'หน่วย',
    'deposit-recharge_status': 'สถานะ',
    'deposit-receipt_currency': 'สกุลเงินที่ได้รับ',
    'withdraw-coin_address': 'ที่อยู่การถอนเหรียญ',
    'withdraw-coin_amount': 'จำนวน',
    'withdraw-handling_fee': 'ค่าธรรมเนียม',
    'withdraw-expected': 'คาดว่า',
    'withdraw-btn-submit': 'ส่งคำขอถอน',
    'withdraw-coin_withdrawal_record': 'บันทึกการถอนเหรียญ',
    'withdraw-num_of_receipt': 'จำนวนใบเสร็จ',
    'withdraw-select_addess': 'กรุณาเลือกที่อยู่สำหรับการถอน',
    'order-position_holding': 'ตำแหน่งถือ',
    'order-pending_orders': 'คำสั่งค้าง',
    'order-history': 'ประวัติ',
    'order-profit_loss': 'กำไรและขาดทุน',
    'order-current_margin': 'มาร์จินปัจจุบัน',
    'order-risk_rate': 'อัตราความเสี่ยง',
    'order-order_id': 'ID คำสั่งซื้อ',
    'order-total_profit_loss': 'กำไรและขาดทุนรวม',
    'order-order_details': 'รายละเอียดคำสั่งซื้อ',
    'order-margin': 'มาร์จิน',
    'order-take_profit': 'รับกำไร',
    'order-set_loss': 'ตั้งค่าการขาดทุน',
    'order-set_TPSL': 'ตั้งค่า TP/SL',
    'order-close_position': 'ปิดตำแหน่ง',
    'order-cancel_order': 'ยกเลิกคำสั่งซื้อ',
    'order-select_period': 'เลือกช่วงเวลา',
    'order-btn_rise': 'ขึ้น',
    'order-btn_fall': 'ลง',
    'order-estimated_return': 'ผลตอบแทนที่คาดการณ์',
    'order-purchase_amount': 'จำนวนการซื้อ',
    'search-search_product': 'ค้นหาผลิตภัณฑ์',
    'search-search_result': 'ผลลัพธ์การค้นหา',
    'trade-market_price': 'ราคาตลาด',
    'trade-price': 'ราคา',
    'trade-quick_trade': 'การซื้อขายด่วน',
    'trade-please_enter_price': 'กรุณากรอกราคา',
    'trade-each_lots': 'แต่ละล็อต',
    'trade-estimated_handling_fee': 'ค่าธรรมเนียมที่ประมาณการ',
    'trade-estimated_margin': 'มาร์จินที่ประมาณการ',
    'trade-lots_text': 'ล็อต (ขั้น: 0.01)',
    'trade-order_page': 'หน้าคำสั่งซื้อ',
    'trade-your_order_has_been_confirmed': 'คำสั่งซื้อของคุณได้รับการยืนยันแล้ว',
    'trade-multiplier': 'ตัวคูณ',
    'accHistory-financial_records': 'บันทึกทางการเงิน',
    'accHistory-records': 'บันทึก',

    'Res_Msg1': 'กรุณาใส่อีเมล',
    'Res_Msg2': 'รูปแบบอีเมลไม่ถูกต้อง',
    'Res_Msg3': 'กรุณาใส่รหัสผ่าน',
    'Res_Msg4': 'รหัสผ่านและรหัสผ่านยืนยันไม่ตรงกัน',
    'Res_Msg5': 'รหัสผ่านต้องมีความยาวไม่น้อยกว่า 6 ตัวอักษร',
    'Res_Msg6': 'รหัสยืนยันไม่ถูกต้อง',
    'Res_Msg7': 'กรุณาใส่รหัสยืนยัน',
    'Res_su': 'ลงทะเบียนสำเร็จ',
    'Login_su': 'เข้าสู่ระบบสำเร็จ',
    'Loading': 'ไม่มีข้อมูลเพิ่มเติม',
    'SDealCont_TopName': 'การจัดส่ง',
    'SDealCont_L1': 'ราคาซื้อ',
    'SDealCont_L2': 'ราคาปัจจุบัน',
    'SDealCont_L3': 'ทิศทาง',
    'SDealCont_L4': 'จำนวน',
    'SDealCont_L5': 'เวลาการจัดส่ง',
    'SDealCont_btn': 'ปิด',
    'SDealCont_L6': 'ซื้อยาว',
    'SDealCont_L7': 'ขายสั้น',
    'SDealCont_L8': 'ซื้อ',
    'SDealCont_L9': 'ขาย',
    'verify-verified': 'การตรวจสอบสำเร็จ',
    'verify-notVerified': 'การตรวจสอบล้มเหลว'
}
