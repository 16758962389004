<template>
  <div id="withdraw">
    <van-form @submit="submitWithdraw">
      <div
        class="bg-general v-mx-10 v-pb-10 v-rounded-bottom custom-bottom-shadow"
      >
        <van-cell-group inset class="bg-general">
          <div class="customBottomLine">
            <van-field
              readonly
              clickable
              :label="$t('bind-bank-payee-account')"
              v-model="payeeAccount"
              :value="payeeAccount"
              :placeholder="getPlaceholder()"
              class="bg-general"
              :class="payeeAccount ? 'custom-field' : ''"
              right-icon="arrow"
              style="font-size: 3.2vw"
              @click="handleClick"
            />
            <!-- Currency Picker -->
            <van-popup v-model:show="showAccountPicker" position="bottom" round>
              <van-picker
                show-toolbar
                v-if="accountList.length > 0"
                :columns="accountList"
                :cancel-button-text="$t('public-text-cancel')"
                :confirm-button-text="$t('public-text-confirm')"
                @cancel="onCancel"
                @confirm="onConfirm"
              />
            </van-popup>
          </div>
          <div class="customBottomLine">
            <van-field
              style="font-size: 3.2vw"
              class="bg-general"
              v-model="withdrawAmount"
              type="number"
              :label="$t('withdraw-coin_amount') + `(USD)`"
              :placeholder="$t('withdraw-coin_amount')"
            >
              <template #button>
                <van-button
                  class="bg-general text-color-5"
                  size="small"
                  plain
                  style="font-size: 3.26667vw; border: 0"
                  >USD</van-button
                >
              </template>
            </van-field>
          </div>
          <div class="customBottomLine">
            <van-field
              style="font-size: 3.2vw"
              class="bg-general"
              v-model="remarks"
              :label="$t('public-text-remarks')"
              :placeholder="$t('public-text-remarks')"
              :cancel-button-text="$t('public-text-cancel')"
              :confirm-button-text="$t('public-text-confirm')"
            />
          </div>
          <div class="k-text v-px-10 v-text-12">
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("withdraw-handling_fee") }}</span>
              <span>{{ handlingFee }}</span>
            </div>
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("withdraw-expected") }}</span>
              <span>--</span>
            </div>
            <div class="flex items-center justify-between v-mt-10">
              <span>{{ $t("public-text-balance") }}</span>
              <span>{{ userInfo.balance + ` USD` }}</span>
            </div>
          </div>
          <div class="flex justify-center v-my-20 v-px-12">
            <van-button
              size="normal"
              round
              class="w-[70%] btn-blue v-border-0"
              native-type="submit"
              >{{ $t("withdraw-btn-submit") }}</van-button
            >
          </div>
        </van-cell-group>
      </div>
    </van-form>
    <van-dialog
      v-model:show="showDialog"
      :message="$t('popupHint-set_up_collection_account')"
      show-cancel-button
      :cancel-button-text="$t('public-text-cancel')"
      :confirm-button-text="$t('public-text-confirm')"
      @confirm="onDialogConfirm"
      @cancel="onDialogCancel"
    />
    <!-- 历史记录列表 -->
    <WithdrawRecord
      :curType="$t('deposit-bank-card')"
      :submit-action="submitAction"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import { useStore } from "vuex";
import { showToast } from "/node_modules/vant";
import WithdrawRecord from "@/pages/components/withdraw/components/withdrawRecord.vue";
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const router = useRouter();
const storeA = useStore();
const userInfo = computed(() => storeA.getters.GetInfoData);
const showDialog = ref(false);
const withdrawAmount = ref("");
const remarks = ref("");
const submitAction = ref(false);

const handlingFee = ref("0");
const accountList = ref([
  // { text: "aaaa", value: "aaaa" },
  // { text: "bbbb", value: "bbbb" },
]);
const payeeAccount = ref(
  accountList.value.length > 0 ? accountList.value[0].text : ""
);
const getPlaceholder = () => {
  return accountList.value.length === 0
    ? t("bind-bank-payee-account")
    : payeeAccount.value;
};
const showAccountPicker = ref(false);
const onConfirm = (val) => {
  if (val.selectedOptions.length > 0) {
    payeeAccount.value = val.selectedOptions[0].text;
    showAccountPicker.value = false;
  }
};
const onCancel = () => {
  showAccountPicker.value = false;
};

const handleClick = () => {
  if (accountList.value.length === 0) {
    showDialog.value = true;
  } else {
    showAccountPicker.value = true;
  }
};

const onDialogConfirm = () => {
  showDialog.value = false;
  router.push({ name: "WithdrawType", query: { type: "1" } });
};

const onDialogCancel = () => {
  showDialog.value = false; // Close dialog if canceled
};

onMounted(async () => {
  await GetData();
});

const walletChoice = ref("");

async function GetData() {
  // 提币地址
  const addressList = await Server.get("/api/wallet/getWalletList", {
    params: { type: "bank" },
  });
  addressList.map((item) => {
    accountList.value.push({
      text: item.payee_account,
      value: item.id,
    });
  });
  if (accountList.value.length > 0) {
    payeeAccount.value = accountList.value[0].text;
    walletChoice.value = accountList.value[0].value;
  }
}

const submitWithdraw = async () => {
  if (!payeeAccount.value || !withdrawAmount.value) {
    showToast({
      message: t("systemHint-missing_params-or-val-error"),
      icon: "fail",
    });
  } else {
    submitAction.value = true;
    await Server.post("/api/wallet/withdraw", {
      amount: withdrawAmount.value,
      wallet_id: walletChoice.value,
      remark: remarks.value,
    });
    showToast({
      message: t("systemHint-operation_successful"),
      icon: "success",
    });
    clearForm();
  }
  submitAction.value = false;
};

const clearForm = () => {
  withdrawAmount.value = "";
  remarks.value = "";
};
</script>

<style scoped>
.box-shadow-bottom {
  box-shadow: 0 2px 4px rgba(161, 161, 161, 0.09);
}
</style>
