export default {
    'app-title': 'FBS',
    'app-title-2': 'Não Vendável',
    'app-title-language': 'Idioma',
    'title-detail': 'Detalhes',
    'title-welcome_to_use': 'Bem-vindo ao uso',

    //tabBar
    'tabbar-market': 'Mercado',
    'tabbar-order': 'Pedidos',
    'tabbar-news': 'Notícias',
    'tabbar-mine': 'Meu Perfil',
    'public-night_mode': 'Modo Noturno',
    'public-noRecord': 'Nenhum dado disponível',
    'public-text-cancel': 'Cancelar',
    'public-text-confirm': 'Confirmar',
    'public-text-balance': 'Saldo',
    'public-text-date': 'Data',
    'public-text-completed': 'Concluído',
    'public-text-invalid': 'Inválido',
    'public-text-unchecked': 'Não verificado',
    'public-text-remarks': 'Observações',
    'public-text-loading': 'Carregando',
    'public-text-loose-to-refresh': 'Puxe para atualizar...',
    'public-text-buy': 'Comprar',
    'public-text-sell': 'Vender',
    'public-text-close': 'Fechado',
    'public-text-lots_1': 'Lotes',
    'public-text-lots': 'Lotes',
    'public-text-available': 'Disponível',
    'public-text-freeze': 'Congelado',
    'public-text-scroll_down_refresh': 'Puxe para baixo para atualizar...',
    'public-btn-submit': 'Enviar',
    'public-btn-add': 'Adicionar',
    'public-btn-copy': 'Copiar',
    'public-btn-delete': 'Excluir',
    'public-btn-copyLink': 'Copiar Link de Convite',
    'public-btn-send': 'Enviar',
    'public-btn-download': 'Baixar',
    'public-btn-resetPassword': 'Redefinir Senha',
    'public-btn-confirmation': 'Confirmação',
    'public-btn-accept': 'Aceitar',
    'public-enterEmail': 'Insira seu e-mail',
    'public-password': 'Senha',
    'public-retypePassword': 'Digite a senha novamente',
    'public-inviteCode': 'Código de Convite',
    'public-verifyCode': 'Código de Verificação',
    'login-newUser': 'Novo Usuário?',
    'login-joinNow': 'Junte-se Agora',
    'login-forgotPassword': 'Esqueceu a Senha',
    'login-btn-login': 'Entrar',
    'login-btn-demoAcc': 'Não precisa abrir uma conta, crie uma conta demo para negociar agora!',
    'login-enterEmail': 'Por favor, insira seu endereço de e-mail',
    'login-enterPassword': 'Por favor, insira sua senha',
    'regsiter-btn-register': 'Registrar',
    'regsiter-enterInviteCode': 'Por favor, insira o código de convite',
    'regsiter-enterVerityCode': 'Por favor, insira o código de verificação',
    'logout-title': 'Sair',
    'systemHint-reset_successful': 'Redefinição bem-sucedida',
    'systemHint-incorrectVerityCode': 'Código de Verificação Incorreto',
    'systemHint-passwordsDoNotMatch': 'As senhas não coincidem',
    'systemHint-password_error': 'Erro na Senha',
    'systemHint-login_connot_find_user': 'Não é possível encontrar este usuário',
    'systemHint-copySuccess': 'Cópia bem-sucedida',
    'systemHint-plsCompleted': 'Por favor, complete',
    'systemHint-pwdInconsistent': 'Senhas Inconsistentes',
    'systemHint-incorrectVerifyCode': 'Código de Verificação Incorreto',
    'systemHint-changeSuccess': 'Alteração bem-sucedida',
    'systemHint-plsSubmit': 'Por favor, envie as informações completas',
    'systemHint-binb-bank-not-open-yet': 'Retirada desta moeda ainda não está disponível',
    'systemHint-plsFillIn': 'Por favor, preencha completamente',
    'systemHint-requestSuccessful': 'Solicitação bem-sucedida',
    'systemHint-operation_successful': 'Operação bem-sucedida',
    'systemHint-set_successful': 'Configuração bem-sucedida',
    'systemHint-missing_params-or-val-error': 'Parâmetros ausentes ou erro no valor fornecido',
    'systemHint-limit_trade_price_must_greater': 'O preço de limite deve ser maior que 0',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'O preço de stop loss de compra (Long) não pode ser maior que o preço de abertura',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'O preço de stop profit de compra (Long) não pode ser maior que o preço de abertura',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'O preço de stop loss de venda (Short) não pode ser menor que o preço de abertura',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'O preço de stop profit de venda (Short) não pode ser menor que o preço de abertura',
    'systemHint-code_send_succesful': 'Código de verificação enviado com sucesso',
    'systemHint-pls_register_login_real_account': 'Por favor, registre-se ou faça login em uma conta real',
    'systemHint-successful_exit': 'Saída bem-sucedida',
    'popupHint-set_up_collection_account': 'Por favor, configure uma conta de coleta primeiro',
    'popupHint-confirm_to_delete': 'Tem certeza de que deseja excluir?',
    'popupHint-whether_return_it': 'Deseja devolvê-lo?',
    'mine-availableAssets': 'Ativos Disponíveis',
    'mine-account_details': 'Detalhes da Conta',
    'mine-deposit': 'Depositar',
    'mine-withdraw': 'Sacar',
    'mine-billDetails': 'Detalhes da Fatura',
    'mine-demo_return_simulated_account': 'Retornar conta simulada',
    'mine-wallet': 'Carteira',
    'mine-verifyName': 'Verificação de Nome Real',
    'mine-inviteFriend': 'Convidar Amigos',
    'mine-changePassword': 'Mudar Senha',
    'mine-onlineSerive': 'Serviço Online',
    'mine-complaintEmail': 'E-mail de Reclamação',
    'mine-announcement': 'Anúncio',
    'mine-establishment': 'Estabelecimento',
    'mine-other': 'Outros',
    'mine-change_theme': 'Mudar Tema',
    'dialog-title': 'Aviso',
    'dialog-logout-conetnt': 'Tem certeza de que deseja sair?',
    'announce-text': 'Por motivos de política, os serviços não são oferecidos para Coreia do Norte, Israel, China, Vanuatu e Cuba.',
    'announce-text-demo': '(FBS Apenas para teste)',
    'verify-text-upload': 'Por favor, faça o upload da frente e do verso do seu passaporte/ID',
    'verify-front': 'Frente',
    'verify-front-desc': 'Faça o upload da frente do seu passaporte/certificado',
    'verify-back': 'Verso',
    'verify-back-desc': 'Faça o upload do verso do seu passaporte/certificado',
    'verify-text-info': 'Por favor, complete suas informações pessoais',
    'verify-name': 'Nome',
    'verify-enterName': 'Por favor, insira seu nome',
    'verify-passportId': 'Número do Passaporte/ID',
    'verify-enterPassportId': 'Por favor, insira o número do seu passaporte/ID',
    'verify-infoAuth': 'Auditoria',
    'wallet-bind-bank': 'Vincular cartão bancário',
    'wallet-bind-currency-address': 'Vincular endereço de moeda digital',
    'bind-bank-name': 'Nome do Banco',
    'bind-bank-address': 'Endereço do Banco',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'Beneficiário',
    'bind-bank-payee-account': 'Conta do Beneficiário',
    'bind-currency': 'Moeda',
    'bind-wallet-address': 'Endereço da Carteira',
    'deposit-digitai-currency': 'Moeda Digital',
    'deposit-bank-card': 'Cartão Bancário',
    'deposit-select_network': 'Selecionar Rede',
    'deposit-coin-number': 'Número de moedas',
    'deposit-coin-placeholder': 'Por favor, insira o número de moedas a serem carregadas',
    'doposit-upload-cert': 'Fazendo upload do Certificado',
    'deposit-record': 'Registro de Depósito',
    'deposit-recharge_amount': 'Valor do Recarregamento',
    'deposit-recharge-placeholder': 'Por favor, insira o valor do recarregamento',
    'deposit-recharge_type': 'Tipo',
    'deposit-recharge_unit': 'Unidade',
    'deposit-recharge_status': 'Status',
    'deposit-receipt_currency': 'Moeda de Recebimento',
    'withdraw-coin_address': 'Endereço para retiradas de moedas',
    'withdraw-coin_amount': 'Quantia',
    'withdraw-handling_fee': 'Taxa de Serviço',
    'withdraw-expected': 'Espera-se que',
    'withdraw-btn-submit': 'Enviar Retirada',
    'withdraw-coin_withdrawal_record': 'Registro de Retirada de Moeda',
    'withdraw-num_of_receipt': 'Número de Recibos',
    'withdraw-select_addess': 'Por favor, selecione um endereço para chamar',
    'order-position_holding': 'Posição Mantida',
    'order-pending_orders': 'Pedidos Pendentes',
    'order-history': 'Histórico',
    'order-profit_loss': 'Lucro e Prejuízo',
    'order-current_margin': 'Margem Atual',
    'order-risk_rate': 'Taxa de Risco',
    'order-order_id': 'ID do Pedido',
    'order-total_profit_loss': 'Lucro e Prejuízo Total',
    'order-order_details': 'Detalhes do Pedido',
    'order-margin': 'Margem',
    'order-take_profit': 'Levar Lucro',
    'order-set_loss': 'Definir Perda',
    'order-set_TPSL': 'Definir TP/SL',
    'order-close_position': 'Fechar Posição',
    'order-cancel_order': 'Cancelar Pedido',
    'order-select_period': 'Selecionar Período',
    'order-btn_rise': 'Subir',
    'order-btn_fall': 'Descer',
    'order-estimated_return': 'Retorno Estimado',
    'order-purchase_amount': 'Valor da Compra',
    'search-search_product': 'Pesquisar Produto',
    'search-search_result': 'Resultados da Pesquisa',
    'trade-market_price': 'Preço de Mercado',
    'trade-price': 'Preço',
    'trade-quick_trade': 'Negociação Rápida',
    'trade-please_enter_price': 'Por favor, insira o preço',
    'trade-each_lots': 'Cada Lote',
    'trade-estimated_handling_fee': 'Taxa de Serviço Estimada',
    'trade-estimated_margin': 'Margem Estimada',
    'trade-lots_text': 'Lotes (Passo: 0.01)',
    'trade-order_page': 'Página de Pedidos',
    'trade-your_order_has_been_confirmed': 'Seu pedido foi confirmado',
    'trade-multiplier': 'Multiplicador',
    'accHistory-financial_records': 'Registros Financeiros',
    'accHistory-records': 'Registros',

    'Res_Msg1': 'Por favor, insira o e-mail',
    'Res_Msg2': 'Formato de e-mail inválido',
    'Res_Msg3': 'Por favor, insira a senha',
    'Res_Msg4': 'A senha e a confirmação da senha não correspondem',
    'Res_Msg5': 'A senha deve ter pelo menos 6 caracteres',
    'Res_Msg6': 'Código de verificação incorreto',
    'Res_Msg7': 'Por favor, insira o código de verificação',
    'Res_su': 'Cadastro realizado com sucesso',
    'Login_su': 'Login bem-sucedido',
    'Loading': 'Não há mais dados',
    'SDealCont_TopName': 'Entrega',
    'SDealCont_L1': 'Preço de compra',
    'SDealCont_L2': 'Preço atual',
    'SDealCont_L3': 'Direção',
    'SDealCont_L4': 'Quantidade',
    'SDealCont_L5': 'Hora de entrega',
    'SDealCont_btn': 'Fechar',
    'SDealCont_L6': 'Compra longa',
    'SDealCont_L7': 'Venda curta',
    'SDealCont_L8': 'Comprar',
    'SDealCont_L9': 'Vender',
    'verify-verified': 'Verificação bem-sucedida',
    'verify-notVerified': 'Verificação falhou'
}
