export default {
    'app-title': 'ДЕМО',
    'app-title-2': 'Не продается',
    'app-title-language': 'Язык',
    'title-detail': 'Детали',
    'title-welcome_to_use': 'Добро пожаловать',

    //tabBar
    'tabbar-market': 'Рынок',
    'tabbar-order': 'Заказы',
    'tabbar-news': 'Новости',
    'tabbar-mine': 'Мой Профиль',
    'public-night_mode': 'Ночной режим',
    'public-noRecord': 'Нет данных',
    'public-text-cancel': 'Отмена',
    'public-text-confirm': 'Подтвердить',
    'public-text-balance': 'Баланс',
    'public-text-date': 'Дата',
    'public-text-completed': 'Завершено',
    'public-text-invalid': 'Неверно',
    'public-text-unchecked': 'Не проверено',
    'public-text-remarks': 'Примечания',
    'public-text-loading': 'Загрузка',
    'public-text-loose-to-refresh': 'Потяните для обновления...',
    'public-text-buy': 'Купить',
    'public-text-sell': 'Продать',
    'public-text-close': 'Закрыто',
    'public-text-lots_1': 'Лоты',
    'public-text-lots': 'Лоты',
    'public-text-available': 'Доступно',
    'public-text-freeze': 'Заморожено',
    'public-text-scroll_down_refresh': 'Прокрутите вниз для обновления...',
    'public-btn-submit': 'Отправить',
    'public-btn-add': 'Добавить',
    'public-btn-copy': 'Копировать',
    'public-btn-delete': 'Удалить',
    'public-btn-copyLink': 'Копировать ссылку для приглашения',
    'public-btn-send': 'Отправить',
    'public-btn-download': 'Скачать',
    'public-btn-resetPassword': 'Сбросить пароль',
    'public-btn-confirmation': 'Подтверждение',
    'public-btn-accept': 'Принять',
    'public-enterEmail': 'Введите ваш email',
    'public-password': 'Пароль',
    'public-retypePassword': 'Повторите пароль',
    'public-inviteCode': 'Код приглашения',
    'public-verifyCode': 'Код проверки',
    'login-newUser': 'Новый пользователь?',
    'login-joinNow': 'Присоединяйтесь сейчас',
    'login-forgotPassword': 'Забыли пароль',
    'login-btn-login': 'Войти',
    'login-btn-demoAcc': 'Не нужно открывать счет, создайте демонстрационный аккаунт для торговли прямо сейчас!',
    'login-enterEmail': 'Пожалуйста, введите ваш адрес электронной почты',
    'login-enterPassword': 'Пожалуйста, введите ваш пароль',
    'regsiter-btn-register': 'Регистрация',
    'regsiter-enterInviteCode': 'Пожалуйста, введите код приглашения',
    'regsiter-enterVerityCode': 'Пожалуйста, введите код проверки',
    'logout-title': 'Выход',
    'systemHint-reset_successful': 'Сброс успешен',
    'systemHint-incorrectVerityCode': 'Неверный код проверки',
    'systemHint-passwordsDoNotMatch': 'Пароли не совпадают',
    'systemHint-password_error': 'Ошибка пароля',
    'systemHint-login_connot_find_user': 'Не удалось найти пользователя',
    'systemHint-copySuccess': 'Успешно скопировано',
    'systemHint-plsCompleted': 'Пожалуйста, заполните',
    'systemHint-pwdInconsistent': 'Пароли не совпадают',
    'systemHint-incorrectVerifyCode': 'Неверный код проверки',
    'systemHint-changeSuccess': 'Изменения успешно внесены',
    'systemHint-plsSubmit': 'Пожалуйста, отправьте полную информацию',
    'systemHint-binb-bank-not-open-yet': 'Вывод этой валюты еще не открыт',
    'systemHint-plsFillIn': 'Пожалуйста, заполните полностью',
    'systemHint-requestSuccessful': 'Запрос выполнен успешно',
    'systemHint-operation_successful': 'Операция выполнена успешно',
    'systemHint-set_successful': 'Настройки сохранены',
    'systemHint-missing_params-or-val-error': 'Отсутствуют параметры или ошибка значения',
    'systemHint-limit_trade_price_must_greater': 'Цена лимитного ордера должна быть больше 0',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'Цена стоп-лосса покупки (Long) не может быть выше цены открытия',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'Цена стоп-профита покупки (Long) не может быть выше цены открытия',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'Цена стоп-лосса продажи (Short) не может быть ниже цены открытия',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'Цена стоп-профита продажи (Short) не может быть ниже цены открытия',
    'systemHint-code_send_succesful': 'Код проверки успешно отправлен',
    'systemHint-pls_register_login_real_account': 'Пожалуйста, зарегистрируйтесь или войдите в реальную учетную запись',
    'systemHint-successful_exit': 'Выход выполнен успешно',
    'popupHint-set_up_collection_account': 'Пожалуйста, сначала настройте счет для сбора',
    'popupHint-confirm_to_delete': 'Вы уверены, что хотите удалить?',
    'popupHint-whether_return_it': 'Вернуть?',
    'mine-availableAssets': 'Доступные активы',
    'mine-account_details': 'Детали счета',
    'mine-deposit': 'Депозит',
    'mine-withdraw': 'Вывод',
    'mine-billDetails': 'Детали счета',
    'mine-demo_return_simulated_account': 'Вернуть имитационный счет',
    'mine-wallet': 'Кошелек',
    'mine-verifyName': 'Проверка реального имени',
    'mine-inviteFriend': 'Пригласить друзей',
    'mine-changePassword': 'Изменить пароль',
    'mine-onlineSerive': 'Онлайн-служба',
    'mine-complaintEmail': 'Электронная почта для жалоб',
    'mine-announcement': 'Объявление',
    'mine-establishment': 'Учреждение',
    'mine-other': 'Прочее',
    'mine-change_theme': 'Сменить тему',
    'dialog-title': 'Сообщение',
    'dialog-logout-conetnt': 'Вы уверены, что хотите выйти?',
    'announce-text': 'Из-за политических причин услуги не предоставляются в Северной Корее, Израиле, Китае, Вануату и Кубе.',
    'announce-text-demo': '(ДЕМО Только для тестирования)',
    'verify-text-upload': 'Пожалуйста, загрузите переднюю и заднюю стороны вашего паспорта/ID',
    'verify-front': 'Лицевая сторона',
    'verify-front-desc': 'Загрузите лицевую сторону вашего паспорта/сертификата',
    'verify-back': 'Обратная сторона',
    'verify-back-desc': 'Загрузите обратную сторону вашего паспорта/сертификата',
    'verify-text-info': 'Пожалуйста, заполните ваши личные данные',
    'verify-name': 'Имя',
    'verify-enterName': 'Пожалуйста, введите ваше имя',
    'verify-passportId': 'Номер паспорта/ID',
    'verify-enterPassportId': 'Пожалуйста, введите номер вашего паспорта/ID',
    'verify-infoAuth': 'Аудит',
    'wallet-bind-bank': 'Привязать банковскую карту',
    'wallet-bind-currency-address': 'Привязать адрес цифровой валюты',
    'bind-bank-name': 'Название банка',
    'bind-bank-address': 'Адрес банка',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'Получатель',
    'bind-bank-payee-account': 'Счет получателя',
    'bind-currency': 'Валюта',
    'bind-wallet-address': 'Адрес кошелька',
    'deposit-digitai-currency': 'Цифровая валюта',
    'deposit-bank-card': 'Банковская карта',
    'deposit-select_network': 'Выберите сеть',
    'deposit-coin-number': 'Количество монет',
    'deposit-coin-placeholder': 'Пожалуйста, введите количество монет для пополнения',
    'doposit-upload-cert': 'Загрузка сертификата',
    'deposit-record': 'Запись о депозите',
    'deposit-recharge_amount': 'Сумма пополнения',
    'deposit-recharge-placeholder': 'Пожалуйста, введите сумму пополнения',
    'deposit-recharge_type': 'Тип',
    'deposit-recharge_unit': 'Единица',
    'deposit-recharge_status': 'Статус',
    'deposit-receipt_currency': 'Валюта получения',
    'withdraw-coin_address': 'Адрес для вывода монет',
    'withdraw-coin_amount': 'Сумма',
    'withdraw-handling_fee': 'Комиссия',
    'withdraw-expected': 'Ожидается',
    'withdraw-btn-submit': 'Отправить запрос на вывод',
    'withdraw-coin_withdrawal_record': 'Запись о выводе монет',
    'withdraw-num_of_receipt': 'Количество квитанций',
    'withdraw-select_addess': 'Пожалуйста, выберите адрес для вызова',
    'order-position_holding': 'Позиция',
    'order-pending_orders': 'Ожидающие заказы',
    'order-history': 'История',
    'order-profit_loss': 'Прибыль и убытки',
    'order-current_margin': 'Текущая маржа',
    'order-risk_rate': 'Риск',
    'order-order_id': 'ID заказа',
    'order-total_profit_loss': 'Общая прибыль и убытки',
    'order-order_details': 'Детали заказа',
    'order-margin': 'Маржа',
    'order-take_profit': 'Взять прибыль',
    'order-set_loss': 'Установить убыток',
    'order-set_TPSL': 'Установить TP/SL',
    'order-close_position': 'Закрыть позицию',
    'order-cancel_order': 'Отменить заказ',
    'order-select_period': 'Выберите период',
    'order-btn_rise': 'Рост',
    'order-btn_fall': 'Падение',
    'order-estimated_return': 'Ожидаемая доходность',
    'order-purchase_amount': 'Сумма покупки',
    'search-search_product': 'Поиск продукта',
    'search-search_result': 'Результаты поиска',
    'trade-market_price': 'Рыночная цена',
    'trade-price': 'Цена',
    'trade-quick_trade': 'Быстрая торговля',
    'trade-please_enter_price': 'Пожалуйста, введите цену',
    'trade-each_lots': 'Каждый лот',
    'trade-estimated_handling_fee': 'Оценочная комиссия',
    'trade-estimated_margin': 'Оценочная маржа',
    'trade-lots_text': 'Лоты (Шаг: 0.01)',
    'trade-order_page': 'Страница заказа',
    'trade-your_order_has_been_confirmed': 'Ваш заказ подтвержден',
    'trade-multiplier': 'Множитель',
    'accHistory-financial_records': 'Финансовые записи',
    'accHistory-records': 'Записи',

     'Res_Msg1': 'Пожалуйста, введите электронную почту',
    'Res_Msg2': 'Неверный формат электронной почты',
    'Res_Msg3': 'Пожалуйста, введите пароль',
    'Res_Msg4': 'Пароль и подтверждение пароля не совпадают',
    'Res_Msg5': 'Пароль должен содержать не менее 6 символов',
    'Res_Msg6': 'Неверный код подтверждения',
    'Res_Msg7': 'Пожалуйста, введите код подтверждения',
    'Res_su': 'Регистрация прошла успешно',
    'Login_su': 'Вход выполнен успешно',
    'Loading': 'Больше данных нет',
    'SDealCont_TopName': 'Доставка',
    'SDealCont_L1': 'Цена покупки',
    'SDealCont_L2': 'Текущая цена',
    'SDealCont_L3': 'Направление',
    'SDealCont_L4': 'Количество',
    'SDealCont_L5': 'Время доставки',
    'SDealCont_btn': 'Закрыть',
    'SDealCont_L6': 'Купить длинную позицию',
    'SDealCont_L7': 'Продать короткую позицию',
    'SDealCont_L8': 'Купить',
    'SDealCont_L9': 'Продать',
    'verify-verified': 'Проверка успешна',
    'verify-notVerified': 'Проверка не пройдена'
}
