<template>
  <div class="bg-main v-m-10 v-p-10 v-mt-30">
    <div class="k-text v-mx-10 v-text-14 font-bold">
      {{ $t("deposit-record") }}
    </div>
    <van-list
      v-model:loading="loading"
      :finished="finished"
      :loading-text="$t('public-text-loading')"
      @load="onLoad"
    >
      <van-cell
        class="bg-general v-text-12 v-rounded-8 base-shadow v-mt-10 v-p-10"
        v-for="(item, index) in filteredDepositRecords"
        :key="index"
      >
        <div class="flex justify-between">
          <div class="flex-1 text-left">
            <div class="text-color-7">
              {{ $t("deposit-recharge_amount") }}
            </div>
            <div class="k-text">{{ parseFloat(item.amount).toFixed(2) }}</div>
          </div>
          <div class="flex-1 text-center">
            <div class="text-color-7">
              {{ $t("deposit-recharge_type") }}
            </div>
            <div class="k-text">
              {{ item.type === "recharge" ? $t("mine-deposit") : "" }}
            </div>
          </div>
          <div class="flex-1 text-right">
            <div class="text-color-7">
              {{ $t("deposit-recharge_unit") }}
            </div>
            <div class="k-text">{{ (item.symbol || "").toUpperCase() }}</div>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex-1 text-left">
            <div class="text-color-7">
              {{ $t("deposit-recharge_status") }}
            </div>
            <div class="k-text">
              {{
                item.status === 0
                  ? $t("verify-infoAuth")
                  : item.status === 1
                  ? $t("verify-verified")
                  : item.status === 2
                  ? $t("verify-notVerified")
                  : ""
              }}
            </div>
          </div>
          <div class="flex-1 text-right">
            <div class="text-color-7">{{ $t("public-text-date") }}</div>
            <div class="k-text">
              {{ new Date(item.ctime * 1000).toLocaleString() }}
            </div>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script setup>
import { reactive, ref, computed, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import Server from "@/Axios/index.ts";

const props = defineProps({
  curType: {
    type: String,
    required: true,
  },
  submitAction: Boolean,
});

const { t } = useI18n();
const router = useRouter();
const filteredDepositRecords = ref([]);
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
let Pageindex = 1;

watch(
  () => props.submitAction,
  async (hasAction) => {
    if (hasAction && hasAction === true) {
      console.log("bobo");
      await GetData();
    }
  }
);

onMounted(async () => {
  await GetData();
});

async function GetData() {
  const arr =
    (await Server.post("/api/wallet/record", {
      page: Pageindex,
      size: "10",
    })) || [];

  if (arr) {
    filteredDepositRecords.value = arr.filter(
      (record) => record?.type === "recharge"
    );
    // 数据全部加载完成
    if (arr.length < 10) {
      finished.value = true;
    }
  }
}

const onLoad = async () => {
  Pageindex++;
  await GetData();
  loading.value = false;
};
</script>

<style scoped></style>
