export default {
    'app-title': 'Demo',
    'app-title-2': 'Satışa Sunulamıyor',
    'app-title-language': 'Dil',
    'title-detail': 'Detaylar',
    'title-welcome_to_use': 'Hoşgeldiniz',

    //tabBar
    'tabbar-market': 'Pazar',
    'tabbar-order': 'Siparişler',
    'tabbar-news': 'Haberler',
    'tabbar-mine': 'Hesabım',
    'public-night_mode': 'Gece Modu',
    'public-noRecord': 'Kayıt Yok',
    'public-text-cancel': 'İptal',
    'public-text-confirm': 'Onayla',
    'public-text-balance': 'Bakiye',
    'public-text-date': 'Tarih',
    'public-text-completed': 'Tamamlandı',
    'public-text-invalid': 'Geçersiz',
    'public-text-unchecked': 'Kontrol Edilmedi',
    'public-text-remarks': 'Açıklama',
    'public-text-loading': 'Yükleniyor',
    'public-text-loose-to-refresh': 'Yenilemek İçin Kaydırın...',
    'public-text-buy': 'Satın Al',
    'public-text-sell': 'Sat',
    'public-text-close': 'Kapat',
    'public-text-lots_1': 'Lot',
    'public-text-lots': 'Lot',
    'public-text-available': 'Kullanılabilir',
    'public-text-freeze': 'Dondurulmuş',
    'public-text-scroll_down_refresh': 'Yenilemek İçin Aşağı Kaydırın...',
    'public-btn-submit': 'Gönder',
    'public-btn-add': 'Ekle',
    'public-btn-copy': 'Kopyala',
    'public-btn-delete': 'Sil',
    'public-btn-copyLink': 'Davet Linkini Kopyala',
    'public-btn-send': 'Gönder',
    'public-btn-download': 'İndir',
    'public-btn-resetPassword': 'Şifreyi Sıfırla',
    'public-btn-confirmation': 'Onayla',
    'public-btn-accept': 'Kabul Et',
    'public-enterEmail': 'E-posta adresinizi girin',
    'public-password': 'Şifre',
    'public-retypePassword': 'Şifreyi Tekrar Girin',
    'public-inviteCode': 'Davet Kodu',
    'public-verifyCode': 'Doğrulama Kodu',
    'login-newUser': 'Yeni Kullanıcı mı?',
    'login-joinNow': 'Şimdi Katıl',
    'login-forgotPassword': 'Şifrenizi Mi Unuttunuz',
    'login-btn-login': 'Giriş Yap',
    'login-btn-demoAcc': 'Hesap Açmadan Demo Hesabı Oluştur ve Şimdi Ticaret Yap!',
    'login-enterEmail': 'E-posta adresinizi girin',
    'login-enterPassword': 'Şifrenizi girin',
    'regsiter-btn-register': 'Kayıt Ol',
    'regsiter-enterInviteCode': 'Davet Kodunu Girin',
    'regsiter-enterVerityCode': 'Doğrulama Kodunu Girin',
    'logout-title': 'Çıkış Yap',
    'systemHint-reset_successful': 'Başarıyla Sıfırlandı',
    'systemHint-incorrectVerityCode': 'Doğrulama Kodu Yanlış',
    'systemHint-passwordsDoNotMatch': 'Şifreler Uyuşmuyor',
    'systemHint-password_error': 'Şifre Hatalı',
    'systemHint-login_connot_find_user': 'Kullanıcı Bulunamadı',
    'systemHint-copySuccess': 'Başarıyla Kopyalandı',
    'systemHint-plsCompleted': 'Lütfen Tamamlayın',
    'systemHint-pwdInconsistent': 'Şifre Uyuşmuyor',
    'systemHint-incorrectVerifyCode': 'Doğrulama Kodu Yanlış',
    'systemHint-changeSuccess': 'Değişiklik Başarıyla Yapıldı',
    'systemHint-plsSubmit': 'Lütfen Bilgileri Tamamlayın',
    'systemHint-binb-bank-not-open-yet': 'Bu para birimi için para çekme işlemi henüz etkin değil',
    'systemHint-plsFillIn': 'Lütfen Tamamlayın',
    'systemHint-requestSuccessful': 'Talep Başarıyla Gerçekleştirildi',
    'systemHint-operation_successful': 'İşlem Başarıyla Gerçekleştirildi',
    'systemHint-set_successful': 'Başarıyla Ayarlandı',
    'systemHint-missing_params-or-val-error': 'Eksik Parametreler veya Değer Hatası',
    'systemHint-limit_trade_price_must_greater': 'Limitli ticaret fiyatı 0’dan büyük olmalıdır',
    'systemHint-buy-stop_loss_price_higher_than_opening_price': 'Alış Stop Loss Fiyatı Açılış Fiyatından Daha Yüksek Olamaz',
    'systemHint-buy-stop_profit_price_higher_than_opening_price': 'Alış Stop Profit Fiyatı Açılış Fiyatından Daha Yüksek Olamaz',
    'systemHint-sell-stop_loss_price_lower_than_opening_price': 'Satış Stop Loss Fiyatı Açılış Fiyatından Daha Düşük Olamaz',
    'systemHint-sell-stop_profit_price_lower_than_opening_price': 'Satış Stop Profit Fiyatı Açılış Fiyatından Daha Düşük Olamaz',
    'systemHint-code_send_succesful': 'Doğrulama Kodu Gönderildi',
    'systemHint-pls_register_login_real_account': 'Lütfen Gerçek Bir Hesap Kayıt Olun veya Giriş Yapın',
    'systemHint-successful_exit': 'Başarıyla Çıkış Yapıldı',
    'popupHint-set_up_collection_account': 'Lütfen Önce Koleksiyon Hesabını Ayarlayın',
    'popupHint-confirm_to_delete': 'Silmek Üzeresiniz Emin Misiniz?',
    'popupHint-whether_return_it': 'Geri Dönmek İster Misiniz',
    'mine-availableAssets': 'Kullanılabilir Varlıklar',
    'mine-account_details': 'Hesap Detayları',
    'mine-deposit': 'Yatırma',
    'mine-withdraw': 'Çekme',
    'mine-billDetails': 'Fatura Detayları',
    'mine-demo_return_simulated_account': 'Simülasyon Hesabına Dön',
    'mine-wallet': 'Cüzdan',
    'mine-verifyName': 'Gerçek Adı Doğrula',
    'mine-inviteFriend': 'Arkadaşları Davet Et',
    'mine-changePassword': 'Şifre Değiştir',
    'mine-onlineSerive': 'Çevrimiçi Servis',
    'mine-complaintEmail': 'Şikayet E-postası',
    'mine-announcement': 'Duyuru',
    'mine-establishment': 'Kuruluş',
    'mine-other': 'Diğer',
    'mine-change_theme': 'Tema Değiştir',
    'dialog-title': 'Uyarı',
    'dialog-logout-conetnt': 'Çıkış Yapmak İstediğinizden Emin Misiniz?',
    'announce-text': 'Politika gereği, hizmet Kuzey Kore, İsrail, Çin, Vanuatu ve Küba’da sunulamamaktadır.',
    'announce-text-demo': '(Demo Sadece Test İçin)',
    'verify-text-upload': 'Lütfen pasaportunuzun/kimlik kartınızın ön ve arka yüzlerini yükleyin',
    'verify-front': 'Ön',
    'verify-front-desc': 'Pasaportunuzun/kimlik kartınızın ön yüzünü yükleyin',
    'verify-back': 'Arka',
    'verify-back-desc': 'Pasaportunuzun/kimlik kartınızın arka yüzünü yükleyin',
    'verify-text-info': 'Lütfen kişisel bilgilerinizi tamamlayın',
    'verify-name': 'Ad',
    'verify-enterName': 'Adınızı girin',
    'verify-passportId': 'Pasaport/ID Numarası',
    'verify-enterPassportId': 'Pasaport/ID numaranızı girin',
    'verify-infoAuth': 'Doğrulama',
    'wallet-bind-bank': 'Banka Kartı Bağla',
    'wallet-bind-currency-address': 'Kripto Para Adresi Bağla',
    'bind-bank-name': 'Banka Adı',
    'bind-bank-address': 'Banka Adresi',
    'bind-bank-swift': 'SWIFT',
    'bind-bank-payee': 'Ödenecek Kişi',
    'bind-bank-payee-account': 'Ödenecek Kişinin Hesabı',
    'bind-currency': 'Para Birimi',
    'bind-wallet-address': 'Cüzdan Adresi',
    'deposit-digitai-currency': 'Kripto Para',
    'deposit-bank-card': 'Banka Kartı',
    'deposit-select_network': 'Ağ Seçin',
    'deposit-coin-number': 'Jeton Sayısı',
    'deposit-coin-placeholder': 'Yatırmak İstediğiniz Jeton Miktarını Girin',
    'doposit-upload-cert': 'Sertifika Yükle',
    'deposit-record': 'Yatırma Kayıtları',
    'deposit-recharge_amount': 'Yatırma Miktarı',
    'deposit-recharge-placeholder': 'Yatırma Miktarını Girin',
    'deposit-recharge_type': 'Tür',
    'deposit-recharge_unit': 'Birim',
    'deposit-recharge_status': 'Durum',
    'deposit-receipt_currency': 'Alınan Para Birimi',
    'withdraw-coin_address': 'Çekme Adresi',
    'withdraw-coin_amount': 'Miktar',
    'withdraw-handling_fee': 'İşlem Ücreti',
    'withdraw-expected': 'Beklenen',
    'withdraw-btn-submit': 'Çekim Talebi Gönder',
    'withdraw-coin_withdrawal_record': 'Jeton Çekme Kayıtları',
    'withdraw-num_of_receipt': 'Makbuz Sayısı',
    'withdraw-select_addess': 'Çekim İçin Adres Seçin',
    'order-position_holding': 'Pozisyon Tutma',
    'order-pending_orders': 'Bekleyen Siparişler',
    'order-history': 'Geçmiş',
    'order-profit_loss': 'Kâr ve Zarar',
    'order-current_margin': 'Mevcut Marjin',
    'order-risk_rate': 'Risk Oranı',
    'order-order_id': 'Sipariş ID',
    'order-total_profit_loss': 'Toplam Kâr ve Zarar',
    'order-order_details': 'Sipariş Detayları',
    'order-margin': 'Marjin',
    'order-take_profit': 'Kâr Al',
    'order-set_loss': 'Zarar Kes',
    'order-set_TPSL': 'TP/SL Ayarla',
    'order-close_position': 'Pozisyonu Kapat',
    'order-cancel_order': 'Siparişi İptal Et',
    'order-select_period': 'Dönem Seçin',
    'order-btn_rise': 'Yüksel',
    'order-btn_fall': 'Düş',
    'order-estimated_return': 'Tahmini Getiri',
    'order-purchase_amount': 'Alış Miktarı',
    'search-search_product': 'Ürün Ara',
    'search-search_result': 'Arama Sonuçları',
    'trade-market_price': 'Pazar Fiyatı',
    'trade-price': 'Fiyat',
    'trade-quick_trade': 'Hızlı Ticaret',
    'trade-please_enter_price': 'Lütfen Fiyatı Girin',
    'trade-each_lots': 'Her Lot',
    'trade-estimated_handling_fee': 'Tahmini İşlem Ücreti',
    'trade-estimated_margin': 'Tahmini Marjin',
    'trade-lots_text': 'Lot (Adım: 0.01)',
    'trade-order_page': 'Sipariş Sayfası',
    'trade-your_order_has_been_confirmed': 'Siparişiniz Onaylandı',
    'trade-multiplier': 'Çarpan',
    'accHistory-financial_records': 'Finansal Kayıtlar',
    'accHistory-records': 'Kayıtlar',

    'Res_Msg1': 'Lütfen e-posta girin',
    'Res_Msg2': 'Geçersiz e-posta formatı',
    'Res_Msg3': 'Lütfen şifre girin',
    'Res_Msg4': 'Şifre ve şifre onayı eşleşmiyor',
    'Res_Msg5': 'Şifre en az 6 karakter uzunluğunda olmalıdır',
    'Res_Msg6': 'Doğrulama kodu yanlış',
    'Res_Msg7': 'Lütfen doğrulama kodunu girin',
    'Res_su': 'Kayıt başarılı',
    'Login_su': 'Giriş başarılı',
    'Loading': 'Daha fazla veri yok',
    'SDealCont_TopName': 'Teslimat',
    'SDealCont_L1': 'Alış fiyatı',
    'SDealCont_L2': 'Mevcut fiyat',
    'SDealCont_L3': 'Yön',
    'SDealCont_L4': 'Miktar',
    'SDealCont_L5': 'Teslimat zamanı',
    'SDealCont_btn': 'Kapat',
    'SDealCont_L6': 'Uzun alım',
    'SDealCont_L7': 'Kısa satış',
    'SDealCont_L8': 'Al',
    'SDealCont_L9': 'Sat',
    'verify-verified': 'Doğrulama başarılı',
    'verify-notVerified': 'Doğrulama başarısız'
}
